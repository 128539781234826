<template>
  <section class="single-product" id="detail">
    <div class="container">
      <div class="col-md-12">
        <div class="col-md-8">
          <div class="product-images demo-gallery">
            <VueSlickCarousel
              class="main-img-slider"
              ref="mainimgslider"
              v-bind="mainCrouSettings"
              @beforeChange="changeThumb"
              v-if="images.length > 0"
            >
              <a
                data-fancybox="gallery"
                v-for="(img, ind) in images"
                :key="ind"
              >
                <img
                  :src="'https://www.justacar.in/lap' + img.url"
                  class="img-fluid"
                  alt=""
                />
              </a>
            </VueSlickCarousel>
            <VueSlickCarousel
              class="thumb-nav"
              ref="thumbnav"
              v-bind="thumbCrouSettings"
              @beforeChange="changeMain"
              v-if="images.length > 0"
              refs="slick"
            >
              <li v-for="(img, ind) in images" :key="ind">
                <img
                  :src="'https://www.justacar.in/lap' + img.url"
                  class="smallimages"
                  alt=""
                />
              </li>
            </VueSlickCarousel>
          </div>
          <div class="product-details">
            <h4>
              {{
                details.make.toUpperCase() +
                " " +
                details.model +
                " " +
                details.modelvariant
              }}
            </h4>
            <div class="name-sec">
              <span>{{ new Date(details.mfgyear).getFullYear() }}</span>
              <span>{{ details.fueltype | fuelType }}</span>
            </div>

            <div class="car-prices">
              <h2 v-if="details.price">&#8377;{{ details.price }}</h2>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="">
            <div class="model-details">
              <!-- <p>
                <strong>Make:</strong>
                <span>{{ details}}</span>
              </p> -->
              <p>
                <strong>Make:</strong>
                <span>{{ details.make.toUpperCase() }}</span>
              </p>
              <p>
                <strong>Model:</strong> <span>{{ details.model }} {{ details.modelvariant}}</span>
              </p>
              <p>
                <strong>Body Type:</strong>
                <span>{{ details.bodytype | bodyType }}</span>
              </p>
              <!-- <p><strong>Drive Type:</strong> <span>N/A</span></p> -->
              <p>
                <strong>Transmission:</strong>
                <span>{{ details.transtype === "M"?"Manual":"Automatic" }}</span>
              </p>
              <p><strong>Seating:</strong> <span>{{details.totalseating}}</span></p>
              <p>
                <strong>Year:</strong>
                <span>{{ new Date(details.mfgyear).getFullYear() }}</span>
              </p>

              <p>
                <strong>Fuel Type:</strong>
                <span>{{ details.fueltype | fuelType }}</span>
              </p>
              <p><strong>Owner No.:</strong> <span>{{details.ownerno}}</span></p>
              <p><strong>Odometer:</strong> <span>{{details.odo}}</span></p>
              <p><strong>Cylinders:</strong> <span>{{details.cylinders}}</span></p>
              <p>
                <strong>Color:</strong>
                <span> {{ details.color.toUpperCase() }}</span>
              </p>
              <!-- <p><strong>VIN:</strong> <span>QEFFD2345L</span></p> -->
              <!-- <p><strong>Offer Type:</strong> <span>N/A</span></p> -->
            </div>
          </div>
          <!-- <div class="call-now">
            <a href="tel:+(123)4567890" target="_blank" class="first-no"
              ><span class="call-sec"
                ><i class="fa fa-phone" aria-hidden="true"></i></span
              >(123)4567890</a
            >
          </div> -->
          <div class="call-now">
            <a href="javascript:void(0)" v-b-modal="'my-modal'">Contact</a>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="my-modal" hide-footer="true" title="INQUIRY FORM">
      <!-- <h3></h3> -->
      <div class="form-group">
        <select class="form-control" id="querytype" v-model="InquiryType">
          <option value="UCP">Used Car Purchase</option>
        </select>
      </div>
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          placeholder="Your full name"
          id="ClientName"
          v-model="ClientName"
        />
      </div>
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          placeholder="Your mobile number"
          id="PhoneNo"
          v-model="PhoneNo"
        />
      </div>
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          placeholder="City you live in"
          id="City"
          v-model="City"
        />
      </div>
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          id="Captcha"
          placeholder="Image text"
          v-model="Captcha"
        />
        <div class="input-group captchsec">
          <img id="captcha" :src="captchaimage" />
          <span class="refresh_icon" v-on:click="getCaptcha()"
            ><i class="fa fa-refresh" aria-hidden="true"></i
          ></span>
        </div>
      </div>
      <div class="btn-section-add">
        <button
          type="button"
          class="btn btn-primary sumbit-btn"
          v-on:click="submitQuery()"
        >
          Submit
        </button>
      </div>
    </b-modal>
  </section>
</template>

<script>
import Axios from "axios";
import VueSlickCarousel from "vue-slick-carousel";
export default {
  name: "Cardetails",
  components: { VueSlickCarousel },
  data() {
    return {
      details: null,
      ClientName: "",
      PhoneNo: "",
      City: "",
      InquiryType: "UCP",
      Captcha: "",
      randomkey: "",
      captchaimage: "",
      images: [],
      mainCrouSettings: {
        asNavFor: this.$refs.thumbnav,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        fade: true,
        autoplay: true,
        // autoplaySpeed: 4000,
        speed: 200,
        lazyLoad: "ondemand",
        dontAnimate: true,
        prevArrow:
          '<div class="slick-prev"><i class="i-prev"></i><span class="sr-only sr-only-focusable"></span></div>',
        nextArrow:
          '<div class="slick-next"><i class="i-next"></i><span class="sr-only sr-only-focusable"></span></div>',
      },
      thumbCrouSettings: {
        asNavFor: this.$refs.mainimgslider,
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: true,
        centerPadding: "0px",
        dots: false,
        centerMode: false,
        draggable: true,
        autoplay: true,
        // autoplaySpeed: 4000,
        speed: 200,
        focusOnSelect: true,
        dontAnimate: true,
        prevArrow:
          '<div class="slick-prev"><i class="i-prev"></i><span class="sr-only sr-only-focusable">Previous</span></div>',
        nextArrow:
          '<div class="slick-next"><i class="i-next"></i><span class="sr-only sr-only-focusable">Next</span></div>',
      },
    };
  },
  mounted() {
    this.getPhotos();
    this.getCaptcha();
  },
  methods: {
    getPhotos() {
      this.details = JSON.parse(sessionStorage.getItem("single_car"));
      this.images = this.details.photos;
      console.log("this.details", this.images);
    },
    changeMain(oldevent, event) {
      this.$refs.mainimgslider.goTo(event);
    },
    changeThumb(oldevent, event) {
      this.$refs.thumbnav.goTo(event);
    },
    getCaptcha: function () {
      this.randomkey = Math.random().toString(36).substring(7);
      Axios.get(
        `https://www.justacar.in/lap/api/Login/GetCaptcha/${this.randomkey}`
      ).then((res) => {
        console.log(res, this.randomkey);
        this.captchaimage = res.data;
      });
    },
    submitQuery: function () {
      if (this.InquiryType === "") {
        alert("Please select query.");
        return false;
      }
      if (this.ClientName === "") {
        alert("Please enter name.");
        return false;
      }
      if (this.PhoneNo === "") {
        alert("Please enter mobile number.");
        return false;
      }
      if (this.City === "") {
        alert("Please enter city.");
        return false;
      }
      if (this.Captcha === "") {
        alert("Please enter image text.");
        return false;
      }
      var data = {
        ClientName: this.ClientName,
        PhoneNo: this.PhoneNo,
        City: this.City,
        InquiryType: this.InquiryType,
        Captcha: this.Captcha,
        CaptchaToken: this.randomkey,
        SaleInqNo  : this.details.inquiryNo,
      };
      Axios.post(
        "https://www.justacar.in/lap/api/Inquiry/SaveOpen",
        new URLSearchParams(data).toString()
      ).then(
        (res) => {
          this.$bvModal.hide("my-modal");
          this.ClientName = "";
          this.PhoneNo = "";
          this.City = "";
          this.InquiryNo = "";
          this.InquiryType = "";
          this.Captcha = "";
          this.getCaptcha();
          console.log("response", res);
          alert(res.data.body);
        },
        (err) => {
          this.$bvModal.hide("my-modal");
          console.log("error", err);
          this.ClientName = "";
          this.PhoneNo = "";
          this.City = "";
          this.InquiryType = "";
          this.Captcha = "";
          this.getCaptcha();
          alert('"Thank you! We will contact you soon."');
        }
      );
    },
  },
};
</script>