<template>
  <div>
    <div class="carloan-section">
      <div class="container-fluid">
        <div class="car-loan-heading">
          <h2>New Car Loan</h2>
          <p>
            With interest rates as low on new car and repayment tenure of up to
            8 years, you can find the most suitable<br />
            vehicle loan for your needs at Justacar.in. You can take the loan
            for 90% to 100% of the<br />
            on-road price of the car.
          </p>
          <div class="view-all-blog">
            <a href="javascript:void(0)" @click="showModal = true">Apply Now</a>
          </div>
        </div>
      </div>
    </div>
    <section class="here-tip-sec add-carloan">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <div class="image-carloan">
              <img src="images/car-loan.png" alt="" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="heading-steps tip-tex">
              <h4>Here's a Tip!</h4>
              <p>
                It is vital that you maintain a good credit score when you apply
                for a car loan. Apart from the loan getting <br />
                approved quicker, lenders will offer low interest rates if your
                credit score is good. No security or collateral is <br />
                required when availing a car loan. The car acts as the security.
              </p>
              <div class="view-all-blog">
                <a href="javascript:void(0)" @click="showModal = true"
                  >Apply Now</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="steps-loan-types">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-10 mx-auto">
            <div class="heading-steps textcar-loan">
              <h4>Eligibility Criteria and Documents Required</h4>
              <p>
                The car loan
                <a href="javascript:volid(0)">eligibility</a> criteria can be
                different for different banks. The common criteria are as
                follows:
              </p>
              <p>
                Age between 18 years and 75 years, Some bank Offer loans to
                higher age person also
              </p>
              <div class="row">
                <div class="col-md-4">
                  <div class="min-steps-sec">
                    <img src="images/identity-proof.png" alt="" />
                    <h5>Identity proof (any of the following)</h5>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >Aadhaar
                    </p>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >Passport
                    </p>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >Driving license
                    </p>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >Voters ID card
                    </p>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >PAN card
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="min-steps-sec">
                    <img src="images/address-proof.png" alt="" />
                    <h5>Address proof (any of the following)</h5>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >Aadhaar
                    </p>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >Passport
                    </p>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >Driving license
                    </p>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >Ration card
                    </p>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >Utility bills
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="min-steps-sec">
                    <img src="images/income.png" alt="" />
                    <h5>Proof of income (Any or Some of the following)</h5>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >Form 16
                    </p>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >Salary slips if you are salaried
                    </p>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >Latest Income Tax Returns
                    </p>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i
                      ></span>
                      Bank statements going back 6 months
                    </p>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >Fard, If Agriculturist
                    </p>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >Electricity Bill, in the name of family memberss
                    </p>
                  </div>
                </div>
              </div>
              <p class="bottom-text">
                To prove your eligibility, you’ll need to provide certain
                documents. <br />
                Though this too is specific to different lenders, the common
                documents will be:
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="customers-trust-types">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-10 mx-auto">
            <div class="heading-steps-trust">
              <h3>Why Justacar.in</h3>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="inner-trust-sec">
                  <div class="img-seccar">
                    <img src="images/help.png" alt="" />
                  </div>
                  <div class="customer-trust">
                    <div class="cHead">
                      We Help you in deciding the Right car for your need
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="inner-trust-sec">
                  <div class="img-seccar">
                    <img src="images/dealers.png" alt="" />
                  </div>
                  <div class="customer-trust">
                    <div class="cHead">
                      We help you to get the the best deal on your dream car
                      from the different dealers
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="inner-trust-sec">
                  <div class="img-seccar">
                    <img src="images/home-services.png" alt="" />
                  </div>
                  <div class="customer-trust">
                    <div class="cHead">
                      We provide the door step service at the comfort of your
                      home/office
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="inner-trust-sec">
                  <div class="img-seccar">
                    <img src="images/rate.png" alt="" />
                  </div>
                  <div class="customer-trust">
                    <div class="cHead">
                      We provide the best interest rates from the different
                      banks
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="inner-trust-sec">
                  <div class="img-seccar">
                    <img src="images/far-deal.png" alt="" />
                  </div>
                  <div class="customer-trust">
                    <div class="cHead">Complete Transparency And fair deal</div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="inner-trust-sec">
                  <div class="img-seccar">
                    <img src="images/schedules.png" alt="" />
                  </div>
                  <div class="customer-trust">
                    <div class="cHead">
                      Schedules of the loan and all the papers works
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="view-all-blog">
                  <a href="javascript:void(0)">Apply Now</a>
               </div> -->
          </div>
        </div>
      </div>
    </section>

    <section class="note-text">
      <div class="container-fluid">
        <div class="row">
          <div class="text-title">
            <marquee
              ><b>Note:</b>Make sure you check exactly what documents the lender
              require from <b>Justacar.in</b>. The documents required can change
              depending on your situation too</marquee
            >
          </div>
        </div>
      </div>
    </section>
    <Inquiryformmodal
      InquiryType="VHL"
      :showTitle="false"
      :showModal="showModal"
      :onClose="onClose"
    />
  </div>
</template>
<script>
import Inquiryformmodal from "./common/Inquiryformmodal";

export default {
  name: "Carloan",
  components: { Inquiryformmodal },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    onClose: function (status) {
      this.showModal = status;
    },
  },
};
</script>