<template>
  <div>
    <h3 v-if="showTitle">INQUIRY FORM</h3>
    <div class="form-group">
      <select class="form-control" id="querytype" v-model="InquiryType">
        <option value="">Select Query</option>
        <option value="VHL">Car Loan</option>
        <option value="UCP">Used Car Purchase</option>
        <option value="UCS">Used Car Sale</option>
        <option value="INS">Insurance</option>
        <option value="HML">Home Loan</option>
        <option value="LAP">Loan Against Property</option>
      </select>
    </div>
    <div class="form-group">
      <input
        type="text"
        class="form-control"
        placeholder="Your full name"
        id="ClientName"
        v-model="ClientName"
      />
    </div>
    <div class="form-group">
      <input
        type="text"
        class="form-control"
        placeholder="Your mobile number"
        id="PhoneNo"
        v-model="PhoneNo"
      />
    </div>
    <div class="form-group">
      <input
        type="text"
        class="form-control"
        placeholder="City you live in"
        id="City"
        v-model="City"
      />
    </div>
    <div class="input-group">
      <input
        type="text"
        class="form-control"
        id="Captcha"
        placeholder="Image text"
        v-model="Captcha"
      />
      <div class="input-group">
        <img id="captcha" :src="captchaimage" />
        <span class="refresh_icon" v-on:click="getCaptcha()"
          ><i class="fa fa-refresh" aria-hidden="true"></i
        ></span>
      </div>
    </div>
    <div class="btn-section">
      <button
        type="button"
        class="btn btn-primary sumbit-btn"
        v-on:click="submitQuery()"
      >
        Submit
      </button>
    </div>
  </div>
</template>
<script>
import Axios from "axios";
export default {
  name: "Inquiryform",
  props: {
    InquiryType: String,
    showTitle:Boolean
  },
  data() {
    return {
      ClientName: "",
      PhoneNo: "",
      City: "",
      Captcha: "",
      randomkey: "",
      captchaimage: "",
    };
  },
  mounted() {
    this.getCaptcha();
  },
  methods: {
    getCaptcha: function () {
      this.randomkey = Math.random().toString(36).substring(7);
      Axios.get(
        `https://www.justacar.in/lap/api/Login/GetCaptcha/${this.randomkey}`
      ).then((res) => {
        console.log(res, this.randomkey);
        this.captchaimage = res.data;
      });
    },
    submitQuery: function () {
      if (this.InquiryType === "") {
        alert("Please select query.");
        return false;
      }
      if (this.ClientName === "") {
        alert("Please enter name.");
        return false;
      }
      if (this.PhoneNo === "") {
        alert("Please enter mobile number.");
        return false;
      }
      if (this.City === "") {
        alert("Please enter city.");
        return false;
      }
      if (this.Captcha === "") {
        alert("Please enter image text.");
        return false;
      }
      var data = {
        ClientName: this.ClientName,
        PhoneNo: this.PhoneNo,
        City: this.City,
        InquiryType: this.InquiryType,
        Captcha: this.Captcha,
        CaptchaToken: this.randomkey,
      };
      Axios.post(
        "https://www.justacar.in/lap/api/Inquiry/SaveOpen",
        new URLSearchParams(data).toString()
      ).then(
        (res) => {
          this.ClientName = "";
          this.PhoneNo = "";
          this.City = "";
          this.InquiryType = "";
          this.Captcha = "";
          this.getCaptcha();
          console.log("response", res);
          alert(res.data.body);
        },
        (err) => {
          console.log("error", err);
          this.ClientName = "";
          this.PhoneNo = "";
          this.City = "";
          this.InquiryType = "";
          this.Captcha = "";
          this.getCaptcha();
          alert('"Thank you! We will contact you soon."');
        }
      );
    },
  },
};
</script>