<template>
  <div>
        <div class="carloan-section contactus">
         <div class="container-fluid">
            <div class="car-loan-heading">
               <h2>Contact Us</h2>
              
            </div>
         </div>
      </div>
      <section class="contact-sec">
          <div class="container">
               <div class="col-md-12">
                   <div class="get-in-touch">
              <h3>Get In Touch</h3>
            
             </div>
             </div>
              <div class="col-md-12 address-contact">
                  <div class="col-md-4">
<div class="address"><p class="blocl-footer1"><span class="map-sec"><i aria-hidden="true" class="fa fa-map-marker"></i></span> 50. Old Grain Market Jagraon-142026<br></p><p class="blocl-footer1"><span class="map-sec"><i aria-hidden="true" class="fa fa-map-marker"></i></span> SCO 29-30, Improvement Trust Building,<br> G.T Road Moga -142001 </p>
</div>
                  </div>
                   <div class="col-md-4">
<div class="mail-sec-con"><a href="https://wa.me/9464684879" target="_blank" class="first-no"><span class="map-sec"><i aria-hidden="true" class="fa fa-whatsapp"></i></span>+91 94-64-6848-79</a></div>
                  <div class="signal-icon-con">
<a href="https://wa.me/9464684879" target="_blank" class="left-side"><img src="images/signal-icon.png" alt=""/>+91  94-64-6848-79</a>
</div>
                  
                  </div>
                   <div class="col-md-4">
<div class="mail-sec-con"><a href="mailto:contact@justacar.in"><span><i aria-hidden="true" class="fa fa-envelope"></i>contact@justacar.in</span></a></div>
                  </div>
                  </div>
             <div class="col-md-12">
<div class="col-md-6">
<div class="map-iframe">
    <div class="ifream-add">
        <h3>Shree Balaji Enterprises</h3>

        <h4>Loan agency<br>
            50, Old Grain Market Rd<br>
           </h4>
        <span class="direction">
            
            <a href="https://www.google.co.in/search?ved=1t:65428&_ga=2.115171143.1396521689.1611834549-1901337941.1610500168&q=Shree+Balaji+enterprises&ludocid=8272777446222228009&lsig=AB86z5VtT9Ubyf0hR2ZTwOpgqOzn#fpstate=lie" target="_blank"><img src="images/nav.png" alt=""/>Directions</a></span>
        <div class="rating-sec">
            <ul>
            <li>5.0</li>
             <li><i class="fa fa-star" aria-hidden="true"></i></li>
               <li><i class="fa fa-star" aria-hidden="true"></i></li>
             <li><i class="fa fa-star" aria-hidden="true"></i></li>
             <li><i class="fa fa-star" aria-hidden="true"></i></li>
             <li><i class="fa fa-star" aria-hidden="true"></i></li>
             <li class="gry">(2)</li>
                </ul>
        </div>
       <div class="bottom-actions"> <a target="_blank" jstcache="2" href="https://maps.google.com/maps?ll=30.796587,75.469668&amp;z=16&amp;t=m&amp;hl=en&amp;gl=IN&amp;mapclient=embed&amp;q=50.%20Old%20Grain%20Market%20Jagraon-142026" jsaction="mouseup:defaultCard.largerMap" class="google-maps-link">View larger map</a></div>
        </div>
    <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3427.215610126664!2d75.46966781466435!3d30.79658733960425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s50.%20Old%20Grain%20Market%20Jagraon-142026!5e0!3m2!1sen!2sin!4v1610433686589!5m2!1sen!2sin" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>

</div>

</div>
<div class="col-md-6">
<div class="map-iframe">
      <div class="ifream-add">
        <h3>Shree Balaji Enterprises</h3>

        <h4>Improvmnet trust building,<br> Moga, Punjab 142001
           </h4>
        <span class="direction">
            
            <a href="https://www.google.co.in/search?ved=1t:65428&_ga=2.115171143.1396521689.1611834549-1901337941.1610500168&q=Shree+Balaji+enterprises&ludocid=8272777446222228009&lsig=AB86z5VtT9Ubyf0hR2ZTwOpgqOzn#fpstate=lie" target="_blank"><img src="images/nav.png" alt=""/>Directions</a></span>
        <div class="rating-sec">
            <ul>
            <li>5.0</li>
             <li><i class="fa fa-star" aria-hidden="true"></i></li>
               <li><i class="fa fa-star" aria-hidden="true"></i></li>
             <li><i class="fa fa-star" aria-hidden="true"></i></li>
             <li><i class="fa fa-star" aria-hidden="true"></i></li>
             <li><i class="fa fa-star" aria-hidden="true"></i></li>
             <li class="gry">(2)</li>
                </ul>
        </div>
       <div class="bottom-actions"> <a target="_blank" jstcache="2" href="https://maps.google.com/maps?ll=30.796587,75.469668&amp;z=16&amp;t=m&amp;hl=en&amp;gl=IN&amp;mapclient=embed&amp;q=50.%20Old%20Grain%20Market%20Jagraon-142026" jsaction="mouseup:defaultCard.largerMap" class="google-maps-link">View larger map</a></div>
        </div>
    <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d27450.856026021316!2d76.24742699401817!3d30.68022998475384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sSCO%2029-30%2C%20Improvement%20Trust%20Building%2C%20G.T%20Road%20Moga%20-142001!5e0!3m2!1sen!2sin!4v1610434397759!5m2!1sen!2sin" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
</div>

</div>
                </div>
            </div>
        </section>
      </div>
      </template>
      <script>
export default{
    name:"Contactus"
}
 </script>
