<template>
  <div class="slider-section">
    <div class="container-fluid">
      <vue-slick-carousel class="row sliderimage" v-bind="bannerSettings">
        <div class="col-md-12" v-for="(banner, index) in banners" :key="index">
          <div class="slider-image">
            <img :src="banner.url" class="d-inline-block align-top" alt="" />
            <div class="banner-text">
              <div class="banner-text-iner">
                <h2>{{ banner.heading }}</h2>
                <p>{{ banner.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </vue-slick-carousel>
      <div class="main-form-sec" id="inquiry_form">
        <Inquiryform InquiryType="" :showTitle="true"/>
      </div>
    </div>
  </div>
</template>
<script>
import Inquiryform from "./Inquiryform";
import VueSlickCarousel from "vue-slick-carousel";
export default {
  name: "Banner",
  components: { VueSlickCarousel, Inquiryform },
  props: {
    msg: String,
    banners: Array,
  },
  data() {
    return {
      bannerSettings: {
        dots: true,
        arrow: false,
        infinite: true,
        centerMode: false,
        speed: 300,
        slidesToShow: 1,
        // variableWidth: false,
        autoplay: true,
        prevArrow:
          '<div class="slick-prev"><i class="i-prev"></i><span class="sr-only sr-only-focusable"></span></div>',
        nextArrow:
          '<div class="slick-next"><i class="i-next"></i><span class="sr-only sr-only-focusable"></span></div>',
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
};
</script>