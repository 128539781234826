<template>
  <footer>
    <div class="footer-menu-section">
      <div class="container">
        <div class="row">
          <!-- <div class="list-menu">
            <ul>
              <li><a href="javascript:void(0)">Car Loan</a></li>
              <li><a href="javascript:void(0)">Used Car Purchase</a></li>
              <li><a href="javascript:void(0)">Used Car Sale</a></li>
              <li><a href="javascript:void(0)">Insurance</a></li>
              <li><a href="javascript:void(0)">Home Loan</a></li>
              <li><a href="javascript:void(0)">Loan Against Property</a></li>
            </ul>
          </div> -->
        
         
          <div class="footer-lofo">
            <router-link to="/">
              <img src="images/footer_logo.png" atl="logo"
            /></router-link>
          </div>
          
        </div>
        <div class="row footer-sec-two">
          <div class="col-md-5">
            <div class="address">
              <p class="blocl-footer1">
                <span class="map-sec"
                  ><i class="fa fa-map-marker" aria-hidden="true"></i
                ></span>
                50. Old Grain Market Jagraon-142026<br />
              </p>
              <p class="blocl-footer1">
                <span class="map-sec"
                  ><i class="fa fa-map-marker" aria-hidden="true"></i
                ></span>
                SCO 29-30, Improvement Trust Building,<br />
                G.T Road Moga -142001
              </p>
              <a
                href="https://wa.me/+919464684879"
                target="_blank"
                class="first-no"
                ><span class="map-sec"
                  ><i class="fa fa-whatsapp" aria-hidden="true"></i></span
                >+91 94-64-6848-79</a
              >
            </div>
          </div>
       
          <div class="col-md-3">
            <div class="address">
              <h3>
                <span><i class="fa fa-link" aria-hidden="true"></i></span
                >Other Links
              </h3>
              <ul>
                <li><router-link to="/about-us">About Us</router-link></li>
                  <li><router-link to="/become-dealer">Become a Dealer</router-link></li>
                    <li><router-link to="/career">Career</router-link></li>
                      <li><router-link to="/contact-us">Contact Us</router-link></li>
                </ul>
              <a href="mailto:contact@justacar.in"
                ><span
                  ><i class="fa fa-envelope" aria-hidden="true"></i
                  >contact@justacar.in</span
                ></a
              >
            </div>
          </div>
             <div class="col-md-4 addresssection">
            <div class="address">
              <h3>
                <span><i class="fa fa-clock-o" aria-hidden="true"></i></span>Car
                Store
              </h3>
              <p>Mon-Sat: 10:00 am – 5:30 pm<br /></p>
            </div>
            <div class="socail-links">
              <ul>
                <li>
                  <a href="https://www.facebook.com/justacar.in/"
                    ><i class="fa fa-facebook" aria-hidden="true"></i
                  ></a>
                </li>
                <li>
                  <a href="https://www.instagram.com/just_acar/"
                    ><i class="fa fa-instagram" aria-hidden="true"></i
                  ></a>
                </li>
                <li>
                  <a href="https://twitter.com/JustACar_in"
                    ><i class="fa fa-twitter" aria-hidden="true"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="col-md-2">
            <div class="address">
              <h3>
                <span class="evelope"
                  ><i class="fa fa-envelope" aria-hidden="true"></i></span
                >Newsletter
              </h3>
              <form>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Email Address"
                    id="email"
                  />
                </div>
                <div class="btn-subscribe">
                  <button type="submit" class="btn btn-primary sumbit-btn">
                    Subscribe
                  </button>
                </div>
              </form>
            </div>
          </div> -->
        </div>
        <div class="col-md-12"></div>
      </div>
    </div>
    <div class="whatsapp-icon">
      <a href="https://wa.me/9464684879" target="_blank" class="left-side"
        ><i class="fa fa-whatsapp" aria-hidden="true"></i
      ></a>
    </div>
    <div class="signal-icon">
      <a href="tel:94-64-6848-79" target="_blank" class="left-side"
        ><img src="images/signal.png" alt=""
      /></a>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
  props: {
    msg: String,
  },
};
</script>