<template>
  <div>
    <section class="sell-car-sec">
      <div class="container">
        <div class="col-md-12">
          <div class="sell-car-text">
            <h3>The way selling your car should be</h3>
            <div class="sell-car-btn">
              <a href="javascript:void(0)" @click="onClose(true)">Schedule Your Offer</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="sell-all-kind">
      <div class="container">
        <div class="col-md-12">
          <div class="sell-kind-banner">
            <img src="images/car5.jpg" atl="" />
            <div class="sell-kind-text">
              <h4>We Sell all kinds of cars</h4>
              <div class="view-all-blog">
                <a href="javascript:void(0)" @click="onClose(true)">Apply Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="sell-buy-sec">
      <div class="container">
        <div class="row">
          <div class="col-md-9 mx-auto">
            <div class="row">
              <div class="col-md-6">
                <div class="image-car-sell">
                  <img src="images/car2.jpg" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="sell-your-text">
                  <h3>We’ll sell yours even if you don’t buy ours</h3>
                  <p>
                    Whether it goes on our lot or to auction, we want to<br />
                    make an offer for your car.
                  </p>
                </div>
              </div>
            </div>
            <div class="row order-sec-sell">
              <div class="col-md-6 order-sec1">
                <div class="sell-your-text">
                  <h3>An offer you can count on</h3>
                  <p>
                    No matter where you sell, start with a<br />
                    custom offer from Justacar.
                  </p>
                </div>
              </div>
              <div class="col-md-6 order-sec2">
                <div class="image-car-sell">
                  <img src="images/car-sell4.jpg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="schedule-your-free">
      <div class="container">
        <div class="col-md-12">
          <div class="col-md-7 maine-scde-sec">
            <div class="schedule-inner-sec">
              <h3>Schedule your free, custom offer</h3>

              <p>Who should we contact to confirm?</p>
              <p></p>
              <div class="form-start-help">
                <div class="col-md-6">
                  <div class="form-group">
                    <select class="form-control" id="sel1">
                      <option>New car:</option>
                      <option>Insurance /Claim</option>
                      <option>Home Loan</option>
                      <option>Loan Against property</option>
                      <option>Used car Sale</option>
                      <option>Used car Purcase</option>
                      <option>Feed back and suggestion</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Name"
                      id="name"
                    />
                    <span class="user_icons"
                      ><i class="fa fa-user" aria-hidden="true"></i
                    ></span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Enter Email"
                      id="name"
                    />
                    <span class="user_icons"
                      ><i class="fa fa-envelope" aria-hidden="true"></i
                    ></span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      placeholder="Enter Mobile Number"
                    />
                    <span class="user_icons"
                      ><i class="fa fa-mobile" aria-hidden="true"></i
                    ></span>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <textarea
                      class="form-control"
                      rows="5"
                      id="comment"
                      placeholder="Massage:"
                    ></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="btn-section">
                    <button type="button" class="btn btn-primary sumbit-btn">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="what-to-bring-sec">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="sell-your-text">
              <h3>What to bring with you</h3>
              <p>Requirements vary from state to state.</p>
            </div>
          </div>
          <div class="col-md-10 mx-auto">
            <div class="row">
              <div class="col-md-7">
                <div class="contant-bring-image">
                  <img src="images/sell-men.jpg" alt="" />
                </div>
              </div>
              <div class="col-md-5">
                <div class="contant-bring-form">
                  <div class="form-group">
                    <label for="sel1">Select list:</label>
                    <select class="form-control" id="sel1">
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                    </select>
                  </div>
                  <div class="payment-sectionadd">
                    <div class="maine-radio-btn-sec">
                      <p>Are you making payments on the car?</p>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input
                            type="radio"
                            class="form-check-input"
                            name="optradio"
                          />Yes
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label payment-radio-btn">
                          <input
                            type="radio"
                            class="form-check-input"
                            name="optradio"
                          />No
                        </label>
                      </div>
                    </div>
                    <div class="maine-radio-btn-sec">
                      <p>Is there more than one person on the title?</p>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input
                            type="radio"
                            class="form-check-input"
                            name="optradio"
                          />Yes
                        </label>
                      </div>
                      <div class="form-check-inline payment-radio-btn">
                        <label class="form-check-label">
                          <input
                            type="radio"
                            class="form-check-input"
                            name="optradio"
                          />No
                        </label>
                      </div>
                    </div>
                    <div class="maine-radio-btn-sec">
                      <p>Can everyone on the title come to Justacar?</p>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input
                            type="radio"
                            class="form-check-input"
                            name="optradio"
                          />Yes
                        </label>
                      </div>
                      <div class="form-check-inline payment-radio-btn">
                        <label class="form-check-label">
                          <input
                            type="radio"
                            class="form-check-input"
                            name="optradio"
                          />No
                        </label>
                      </div>
                    </div>
                    <div class="bring-btn">
                      <a href="javascript:void(0)">What to bring with you</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Inquiryformmodal
      InquiryType="UCS"
      :showTitle="false"
      :showModal="showModal"
      :onClose="onClose"
    />
  </div>
</template>
<script>
import Inquiryformmodal from "./common/Inquiryformmodal";
export default {
  name: "Sellcar",
  components: { Inquiryformmodal },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    onClose: function (status) {
      this.showModal = status;
    },
  },
};
</script>