<template>
  <section class="secarch-your-car">
    <div class="container">
      <div class="row inner-search-sec">
        <h2>Search your favorite car</h2>
        <div class="inner-sec-form" name="homesearch">
          <div class="col-md-7">
            <div class="row">
              <div class="col-md-2">
                <div class="togglesec">
                  <div class="dropdown menuselection">
                    <button
                      type="button"
                      class="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      <i class="fa fa-bars" aria-hidden="true"></i>Any
                    </button>
                    <!-- <div class="dropdown-menu">
                      <a class="dropdown-item" href="javascript:void(0)">
                        <i class="fa fa-bars" aria-hidden="true"></i> Any
                      </a>
                      <a class="dropdown-item" href="javascript:void(0)">
                        <i class="fa fa-address-book"></i> Individual
                      </a>
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="col-md-10">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <select
                        class="form-control"
                        id="search_make"
                        v-model="search_make"
                        v-on:change="getModels($event)"
                      >
                        <option value="">Make</option>
                        <option
                          v-for="(brand, key) in brands"
                          :key="key"
                          :value="brand"
                        >
                          {{ brand.toUpperCase() }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <select
                        class="form-control"
                        id="search_model"
                        v-model="search_model"
                      >
                        <option value="">Model</option>
                        <option
                          v-for="(model, key) in models"
                          :key="key"
                          :value="model.modelvalue"
                        >
                          {{ model.modelname }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <select
                        class="form-control"
                        id="search_fuel_type"
                        v-model="search_fuel_type"
                      >
                        <option value="">Fuel Type</option>
                        <option value="DL">Diesel</option>
                        <option value="HY">Hybrid</option>
                        <option value="EL">Electric</option>
                        <option value="LP">LPG</option>
                        <option value="CN">CNG</option>
                        <option value="PT">Petrol</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <select
                    class="form-control"
                    id="search_transmission"
                    v-model="search_transmission"
                  >
                    <option value="">Transmission</option>
                    <option value="M">Manual</option>
                    <option value="AT">Automatic</option>
                  </select>
                </div>
              </div>
              <div class="col-md-5">
                <h3>&#8377;{{ search_price }}</h3>
                <div class="form-group">
                  <input
                    type="range"
                    min="1"
                    max="2000000"
                    class="form-control-range"
                    id="myRange"
                    v-model="search_price"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="searchbtn">
                  <a href="javascript:void(0)" v-on:click="searchQuery"
                    >Search</a
                  >
                </div>
              </div>
              <!-- <div class="col-md-4 padding-sec">
                <div class="advancesearch">
                  <select class="form-control" id="sel1">
                    <option value="">Advance Search</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                  </select>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import Axios from "axios";
const modeldata = require("../makemodels.json");
export default {
  name: "Homesearch",
  data() {
    return {
      search_make: "",
      search_model: "",
      search_fuel_type: "",
      search_transmission: "",
      search_price: 2000000,
      brands: [],
      models: [],
    };
  },
  methods: {
    searchQuery: function () {
      var data = {
        MakeId: this.search_make !== "" ? this.search_make : null,
        FuelType: this.search_fuel_type !== "" ? this.search_fuel_type : null,
        TransType:
          this.search_transmission !== "" ? this.search_transmission : null,
        OrderBy: "1",
        OrderAsc: false,
        PageNo: 1,
        PageSize: 36,
        ValueStart: 0,
        ValueEnd: this.search_price,
        model: this.search_model,
      };
      sessionStorage.setItem("search_payload", JSON.stringify(data));
      this.$router.push("/search");
    },
    getModels: function (e) {
      var brand = e.target.value;
      this.models = [];
      modeldata.forEach((make) => {
        if (brand === make.brand) {
          this.models.push(make);
        }
      });
    }
  },
  mounted() {
    modeldata.forEach((make) => {
      if (!this.brands.includes(make.brand)) {
        this.brands.push(make.brand);
      }
    });
  },
};
</script>