<template>
  <section class="testimonial-sec">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="testimonial-img">
            <img src="images/car-img.png" alt="" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="testimonial-text">
            <h4>Testimonial</h4>
            <VueSlickCarousel
              class="slider-testimonial"
              v-bind="testimonialsSettings"
            >
              <div class="inner-testimonal" v-for="(tes,ind) in testimonials" :key="ind">
                <div class="text-test">
                  <p>
                    {{tes.message}}
                    {{tes.message}}
                  </p>
                  <span
                    ><strong>{{tes.name}}</strong> {{tes.city}}
                  </span>
                </div>
              </div>
              <!-- <div class="inner-testimonal">
                <div class="text-test">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type.
                  </p>
                  <span
                    ><strong>Charles M. WorshamToyota</strong> C-HR Eclipse
                  </span>
                </div>
              </div>
              <div class="inner-testimonal">
                <div class="text-test">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type.
                  </p>
                  <span
                    ><strong>Charles M. WorshamToyota</strong> C-HR Eclipse
                  </span>
                </div>
              </div>
              <div class="inner-testimonal">
                <div class="text-test">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type.
                  </p>
                  <span
                    ><strong>Charles M. WorshamToyota</strong> C-HR Eclipse
                  </span>
                </div>
              </div> -->
            </VueSlickCarousel>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
export default {
  name: "Testimonials",
  components: { VueSlickCarousel },
  props: {
    testimonials: Array,
  },
  data() {
    return {
      testimonialsSettings: {
        dots: true,
        autoplay: false,
        infinite: true,
       
        speed: 300,
        slidesToShow: 1,
       
        prevArrow:
          '<div class="slick-prev"><i class="i-prev"></i><span class="sr-only sr-only-focusable"></span></div>',
        nextArrow:
          '<div class="slick-next"><i class="i-next"></i><span class="sr-only sr-only-focusable"></span></div>',
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
};
</script>