<template>
  <div>
        <div class="carloan-section hone-insurance">
         <div class="container-fluid">
            <div class="car-loan-heading">
               <h2>Housing Loans</h2>
              
            </div>
         </div>
      </div>
      <section class="home-loan">
          <div class="container">
                <div class="row car-type-maine-sec">
                  <div class="col-md-7 mx-auto">
                     <div class="col-md-4">
                        <div class="car-loan-types">
                           <a href="javascript:void(0)">
                                <img src="images/home-loans.png" alt=""/>
                              <div class="loan-name"><h3>Home Loans</h3></div>
                            
                           </a>
                        </div>
                     </div>
                     <div class="col-md-4">
                        <div class="car-loan-types">
                           <a href="javascript:void(0)">
                                <img src="images/plot-loans.png" alt=""/>
                              <div class="loan-name"><h3>Plot Loans</h3></div>
                           
                           </a>
                        </div>
                     </div>
                     <div class="col-md-4">
                        <div class="car-loan-types">
                           <a href="javascript:void(0)">
                                 <img src="images/rural-loan.png" alt=""/>
                              <div class="loan-name"><h3>Rural Housing Loans</h3></div>
                            
                           </a>
                        </div>
                     </div>
                  </div>
               </div> 
          </div>
      </section>
       <section class="otthe-home-loan">
          <div class="container">
                <div class="row car-type-maine-sec">
                  <div class="col-md-10 mx-auto landing-threesteps">
                      <div class="threesteps">
                         <h3>Other Home Loan Products</h3>
                      
                      <div class="col-md-12">
                            <div class="col-md-4">
                                <div class="inner-three-sec">
                                    <img src="images/home-improvement.png" alt=""/>
                                    <h4>Home Improvement Loans</h4>
                                    </div>
                            </div>
                             <div class="col-md-4">
                                <div class="inner-three-sec">
                                        <img src="images/home-extension.png" alt=""/>
                                    <h4>Home Extension Loans</h4>
                                    </div>
                            </div>
                             <div class="col-md-4">
                                <div class="inner-three-sec">
                                        <img src="images/top-up.png" alt=""/>
                                    <h4>Top Up Loans</h4>
                                    </div>
                            </div>
                      </div>
                      </div>
                  </div>
                </div>
          </div>
       </section>
           <section class="add-on-covers">
      <div class="container">
        <div class="col-md-12">
          
          <div class="row">
          <div class="col-md-10 mx-auto affordable-sec">
          <div class="col-md-4">
                <div class="inner-text-block">
            <div class="cover-text">
              <h3>AFFORDABLE HOUSING</h3>
             <span> HDFC Reach Loans</span>
               <span> Pradhan Mantri Awas Yojana</span>
               
             
            </div>
             <div class="view-all-blog single-btn">
            <a href="javascript:void(0)">Know more </a>
          </div>
          </div>
          </div>
          <div class="col-md-4">
              <div class="inner-text-block">
            <div class="cover-text">
              <h3>REFINANCE</h3>
                <span>
                    Balance Transfer</span >
            </div>
              <div class="view-all-blog single-btn">
            <a href="javascript:void(0)">Know more </a>
          </div>
           </div>
             </div>
              <div class="col-md-4">
                    <div class="inner-text-block">
            <div class="cover-text">
              <h3>NON RESIDENT INDIAN (NRI)</h3>
             <span> Loan Against Property</span>
               <span>Commercial Property Loans</span>
               <span>Commercial Plot Loans</span>
            
            </div>
             <div class="view-all-blog single-btn">
            <a href="javascript:void(0)">Know more </a>
          </div>
          </div>
              </div>
          </div>
        </div>
       
        </div>
      </div>
    </section>
      <section class="our-partners">
         <div class="container-fluid">
            <div class="row">
               <div class="col-md-10 mx-auto">
                  <h3>Our Partners</h3>
                  <div class="row">
                     <div class="col-md-3">
                        <div class="bank-list">
                           <img src="images/hdfc.png" alt="" />
                           <h3> Hdfc Bank ltd.</h3>
                        </div>
                     </div>
                
                  
               </div>
            </div>
         </div>
         </div>
      </section>
  </div>
</template>
<script>
export default {
    name:"Homeloans"
}
</script>