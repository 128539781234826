<template>
  <div class="homepage-start">
    <Banner :banners="banners" />
    <Latest
      :brands="brands"
      :services="services"
      :popular_cars="popular_cars"
      :viewSingle="viewSingle"
    />
    <Homesearch :brands="brands" />
    <Popularcars
      :popular_cars="popular_cars"
      v-if="popular_cars.length > 0"
      :viewSingle="viewSingle"
    />
    <Popularbrands :brands="brands" />
    <Services :services="services" v-if="services.length > 0" />
    <Testimonials :testimonials="testimonials" v-if="testimonials.length > 0" />
  </div>
</template>
<script>
import Banner from "./common/Banner";
import Latest from "./sections/Latest";
import Homesearch from "./sections/Homesearch";
import Popularcars from "./sections/Popularcars";
import Popularbrands from "./sections/Popularbrands";
import Services from "./sections/Services";
import Testimonials from "./sections/Testimonials";
import Axios from "axios";
export default {
  name: "Home",
  components: {
    Banner,
    Latest,
    Homesearch,
    Popularcars,
    Popularbrands,
    Services,
    Testimonials,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      xyz: new Date().getFullYear(),
      date: new Date(),
      popular_cars: [],
      testimonials: [],
      banners: [
        {
          url:
            "https://raw.githubusercontent.com/justacarin/img/main/banner2.jpg",
          heading: "Car Loan",
          description: "our vision, you gain",
        },
        {
          url:
            "https://raw.githubusercontent.com/justacarin/img/main/banner1.jpg",
          heading: "Insuarance",
          description: "our vision, you gain",
        },
        {
          url: "images/Finance-Banner.png",
          heading: "Car Loan 1",
          description: "our vision, you gain",
        },
        {
          url: "images/Finance-Banner.png",
          heading: "Insuarance 2",
          description: "our vision, you gain",
        },
      ],
      brands: [],
      services: [],
    };
  },
  mounted() {
    Axios.get(`https://www.justacar.in/lap/api/Vehicle/PopularCars`).then((res) => {
      console.log("response popular", res);
      this.popular_cars = res.data.body.items;
      sessionStorage.setItem("popular_cars", JSON.stringify(this.popular_cars));
    });
    Axios.get(`https://www.justacar.in/lap/api/Vehicle/PopularBrands`).then(
      (res) => {
        var brands = [];
        res.data.body.items.forEach((br) => {
          var x = {
            name: br,
            logo: `images/${br}.png`,
            logobig: `images/brand-big/${br}.png`,
          };
          brands.push(x);
        });
        this.brands = brands;
      }
    );
    Axios.get(`https://www.justacar.in/lap/api/Vehicle/Testimonials`).then((res) => {
      this.testimonials = res.data.body.items;
    });
    Axios.get(
      `https://raw.githubusercontent.com/justacarin/img/main/services.json`
    ).then((res) => {
      this.services = res.data;
    });
  },
  methods: {
    viewSingle: function (val) {
      sessionStorage.setItem("single_car", JSON.stringify(val));
      this.$router.push("/details");
    },
  },
};
</script>