<template>
  <div class="col-md-2 rightsideimage">
    <div class="grid-images long-img">
      <img src="images/ads-banner.jpg" alt="" />
       <!-- <div class="banner-text-brand bg-add">
        <div class="banner-text-iner">
          <h4>Car Sale</h4>
         <span>2020</span> 
        </div>
       <div class="banner-text-right">
          <h5>Diesel</h5>
          <span class="price">$62,000</span>
        </div> 
      </div>  -->
    </div>
    <div class="grid-images">
      <img src="images/car-insu.jpg" alt="" />
     <!-- <div class="banner-text-brand bg-add">
        <div class="banner-text-iner">
          <h4>Car Insurance </h4>
          <span>2020</span> 
        </div>
         <div class="banner-text-right">
          <h5>Diesel</h5>
          <span class="price">$62,000</span>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "Searchsidebar",
};
</script>