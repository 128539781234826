<template>
  <div class="col-md-10">
    <singlegrid :items="items" :viewSingle="viewSingle"/>
  </div>
</template>
<script>
import Singlegrid from "./Singlegrid.vue";
export default {
  props: {
    items: Array,
    viewSingle: Function,
  },
  components: { Singlegrid },
  name: "Gridview",
};
</script>