<template>
   <div>
      <section class="about-us-sec">
         <div class="container">
            <div class="col-md-12">
               <div class="sell-car-text text-change-careers">
                  <h3>About Us</h3>
                
               </div>
            </div>
         </div>
      </section>
      <section class="about_us_content">
          <div class="container">
              <div class="col-md-12">
                  <div class="contant-about-innner abouttext_page">
<p>We are the one stop shop for
 Various loans  and services like car loans, used car loans,
Home loans, loan against property,
You can sell your car to the potential buyer  and can buy your dream car online
One can get  the multiple insurances of your choice including Health insurance ,Car insurance, Tw insurance, Travel insurance Personal accident Insurance and many more
We are into this business since 2005 and have tie ups with various banks , insurance companies
We are  providing the platform to used car dealers and interested buyers & sellers to contact us for  cars sale, and find used car to buy.</p>
    <h3>Interested buyers can select car on <a href="/contact-us">Justacar.in</a> and contact us for physical verification of vehicles.</h3>          
             
              </div>
              </div>
          </div>
      </section>
    
   </div>
</template>
<script>
   export default{
       name:"Aboutus"
   }
    
</script>