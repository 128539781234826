<template>
    <header class="header-section">
       <div class="container">
         <nav class="navbar fixed-top navbar-expand-lg navbar-dark pink scrolling-navbar">
            <div class="col-md-12">
               <div class="col-md-3">
                  <router-link class="navbar-brand" to="/"> <img src="images/logo.png" atl="logo"/></router-link>
               </div>
               <div class="col-md-7">
                  <!-- <div class="search-btn">
                     <form class="navbar-form" role="search">
                        <div class="input-group">
                           <input class="form-control" placeholder="How can I help you?" name="srch-term" id="srch-term" type="text"/>
                           <div class="search-group-btn">
                              <i class="fa fa-search" aria-hidden="true"></i>
                           </div>
                        </div>
                     </form>
                  </div> -->
               </div>
               <div class="col-md-2">
                  <div class="icon-sec">
                     <div class="help-desk">
                        <a href="https://www.justacar.in/lap/login.html">
                        <img src="images/user.png" atl=""/>
                        <span>Agent Login</span>
                        </a>
                     </div>
                  </div>
               </div>
            </div>
         </nav>
       </div>
      </header>
</template>
<script>
export default {
  name: 'Header',
  props: {
    msg: String
  }
}
</script>