<template>
  <div>
    <cardetails :images="images" />
    <relatedcars :related_cars="related_cars" v-if="related_cars.length > 0" />
  </div>
</template>

<script>
import Cardetails from "./sections/single-product/Cardetails.vue";
import Relatedcars from "./sections/single-product/Relatedcars.vue";
// var Details = new Vue();
export default {
  name: "Singleproduct",
  components: {
    Cardetails,
    Relatedcars,
  },
  data() {
    return {
      related_cars: sessionStorage.getItem("popular_cars")
        ? JSON.parse(sessionStorage.getItem("popular_cars"))
        : [],
      images: [
        { id: 1, url: "images/img12.png" },
        { id: 2, url: "images/img9.png" },
        { id: 3, url: "images/img12.png" },
        { id: 4, url: "images/img11.png" },
        { id: 5, url: "images/img12.png" },
        { id: 6, url: "images/img9.png" },
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 2000);
  },
};
</script>
