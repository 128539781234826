<template>
  <div>
    <!----Section-part----->
    <div class="carloan-section health-insurance">
      <div class="container-fluid">
        <div class="car-loan-heading">
          <h2>Health Insurance</h2>
          <p>
            Health Insurance is a type of insurance that offers coverage to the
            policy holder for medical expenses in case of a health emergency.<br />
            A health insurance plan chosen by the insured provides coverage for
            different expenses including surgical expenses,<br />
            day-care expenses, and critical illness etc.
          </p>
          <div class="view-all-blog">
            <a href="javascript:void(0)" @click="onClose(true)">Apply Now</a>
          </div>
        </div>
      </div>
    </div>
    <section class="here-tip-sec">
      <div class="container-fluid">
        <div class="col-md-12">
          <div class="heading-steps inssurance-tex">
            <h4>Importance of Health Insurance Plans</h4>
            <p>
              Health emergencies do not come with a prior notice. With sedentary
              lifestyles more and more people are becoming prone to lifestyle<br />
              diseases in India. And with the increased demand for quality
              healthcare services, medical treatment has now become <br />
              quite expensive, especially in the private hospitals. And without
              insurance the hospital bills are enough to drain one’s savings.<br />
              Therefore, a health insurance plan becomes an absolute necessity
              as it offers coverage to the insured family members<br />
              and the policyholder against the exorbitant hospitalization
              expenses in case an accident or illness. Apart from the medical
              <br />
              coverage, health Insurance plans also offer tax benefits on
              premium under section 80D of the Income Tax Act, 1961
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="insurance-types-policy">
      <div class="container">
        <div class="col-md-12">
          <div class="heading-steps inssurance-tex insurance_plans">
            <h4>Types of Health Insurance Plans</h4>
            <p>
              To ensure that the health insurance plan meets your insurance
              needs, it is important to know the <br />
              different types of health insurance plans, to decide the right
              policy.
            </p>
            <h5>
              Mentioned below are the different types of health insurance plans
              you <br />
              can choose as per your insurance requirement:
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col-md-11 mx-auto">
            <div class="col-lg-3 col-md-6">
              <div class="single-work-insurance mt-0 text-center">
                <div class="work-icon">
                  <img class="" src="images/Insurance-policy.png" alt="icon" />
                </div>
                <h5>Individual Health Insurance Plans</h5>
                <p v-if="readmore !== 1">
                  {{
                    str1.length > 311 ? str1.substring(0, 300) + "..." : str1
                  }}
                  <a
                    v-if="str1.length > 311"
                    href=""
                    @click="readMore($event, 1)"
                    >Know More</a
                  >
                </p>
                <p v-if="readmore === 1">
                  {{ str1 }}
                  <a href="" @click="readLess($event)">Know Less</a>
                </p>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="single-work-insurance mt-0 text-center">
                <div class="work-icon">
                  <img class="" src="images/third-pary.png" alt="icon" />
                </div>
                <h5>Family Health Insurance Plans</h5>
                <p v-if="readmore !== 2">
                  {{
                    str2.length > 311 ? str2.substring(0, 300) + "..." : str2
                  }}
                  <a
                    v-if="str2.length > 311"
                    href=""
                    @click="readMore($event, 2)"
                    >Know More</a
                  >
                </p>
                <p v-if="readmore === 2">
                  {{ str2 }}
                  <a href="" @click="readLess($event)">Know Less</a>
                </p>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="single-work-insurance mt-0 text-center">
                <div class="work-icon">
                  <img class="" src="images/health-insurance.png" alt="icon" />
                </div>
                <h5>Senior Citizen Health Insurance Plans</h5>
                <p v-if="readmore !== 3">
                  {{
                    str3.length > 311 ? str3.substring(0, 300) + "..." : str3
                  }}
                  <a
                    v-if="str3.length > 311"
                    href=""
                    @click="readMore($event, 3)"
                    >Know More</a
                  >
                </p>
                <p v-if="readmore === 3">
                  {{ str3 }}
                  <a href="" @click="readLess($event)">Know Less</a>
                </p>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="single-work-insurance mt-0 text-center">
                <div class="work-icon">
                  <img class="" src="images/illness.png" alt="icon" />
                </div>
                <h5>Critical Illness Insurance Plans</h5>
                <p v-if="readmore !== 4">
                  {{
                    str4.length > 331 ? str4.substring(0, 320) + "..." : str4
                  }}
                  <a
                    v-if="str4.length > 331"
                    href=""
                    @click="readMore($event, 4)"
                    >Know More</a
                  >
                </p>
                <p v-if="readmore === 4">
                  {{ str4 }}
                  <a href="" @click="readLess($event)">Know Less</a>
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-11 mx-auto inner-life-plan">
            <div class="col-lg-3 col-md-6">
              <div class="single-work-insurance mt-0 text-center">
                <div class="work-icon">
                  <img class="" src="images/maternity.png" alt="icon" />
                </div>
                <h5>Maternity Health Insurance Plans</h5>
                <p v-if="readmore !== 5">
                  {{
                    str5.length > 311 ? str5.substring(0, 300) + "..." : str5
                  }}
                  <a
                    v-if="str5.length > 311"
                    href=""
                    @click="readMore($event, 5)"
                    >Know More</a
                  >
                </p>
                <p v-if="readmore === 5">
                  {{ str5 }}
                  <a href="" @click="readLess($event)">Know Less</a>
                </p>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="single-work-insurance mt-0 text-center">
                <div class="work-icon">
                  <img class="" src="images/accident.png" alt="icon" />
                </div>
                <h5>Personal Accident Insurance Cover</h5>
                <p v-if="readmore !== 6">
                  {{
                    str6.length > 311 ? str6.substring(0, 300) + "..." : str6
                  }}
                  <a
                    v-if="str6.length > 311"
                    href=""
                    @click="readMore($event, 6)"
                    >Know More</a
                  >
                </p>
                <p v-if="readmore === 6">
                  {{ str6 }}
                  <a href="" @click="readLess($event)">Know Less</a>
                </p>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="single-work-insurance mt-0 text-center">
                <div class="work-icon">
                  <img class="" src="images/group-health.png" alt="icon" />
                </div>
                <h5>Group Health Insurance Plans</h5>
                <p v-if="readmore !== 7">
                  {{
                    str7.length > 311 ? str7.substring(0, 300) + "..." : str7
                  }}
                  <a
                    v-if="str7.length > 311"
                    href=""
                    @click="readMore($event, 7)"
                    >Know More</a
                  >
                </p>
                <p v-if="readmore === 7">
                  {{ str7 }}
                  <a href="" @click="readLess($event)">Know Less</a>
                </p>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="single-work-insurance mt-0 text-center">
                <div class="work-icon">
                  <img class="" src="images/coronavirus.png" alt="icon" />
                </div>
                <h5>Coronavirus Health Insurance Plans</h5>
                <p v-if="readmore !== 8">
                  {{
                    str8.length > 311 ? str8.substring(0, 300) + "..." : str8
                  }}
                  <a
                    v-if="str8.length > 311"
                    href=""
                    @click="readMore($event, 8)"
                    >Know More</a
                  >
                </p>
                <p v-if="readmore === 8">
                  {{ str8 }}
                  <a href="" @click="readLess($event)">Know Less</a>
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-11 mx-auto inner-life-plan">
            <div class="col-lg-3 col-md-6">
              <div class="single-work-insurance mt-0 text-center">
                <div class="work-icon">
                  <img class="" src="images/unity.png" alt="icon" />
                </div>
                <h5>Unit Linked Health Insurance Plans</h5>
                <p v-if="readmore !== 9">
                  {{
                    str9.length > 311 ? str9.substring(0, 300) + "..." : str9
                  }}
                  <a
                    v-if="str9.length > 311"
                    href=""
                    @click="readMore($event, 9)"
                    >Know More</a
                  >
                </p>
                <p v-if="readmore === 9">
                  {{ str9 }}
                  <a href="" @click="readLess($event)">Know Less</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="here-tip-sec">
      <div class="container-fluid">
        <div class="col-md-12">
          <div class="heading-steps inssurance-tex">
            <h4>Why Health Insurance ?</h4>
            <p>
              Let’s check why do you need to buy a health insurance plan in
              India:
            </p>
          </div>
        </div>
        <div class="col-md-12">
          <div class="col-md-6">
            <div class="type-inssrance">
              <ul>
                <li>
                  <span class="check-text"
                    >A health insurance policy can pay for hospitalisation
                    expenses, medication and laboratory test costs, ambulance,
                    doctor fees, etc. Some health plans also cover OPD expenses
                    up to a certain limit.</span
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-6">
            <div class="type-inssrance">
              <ul>
                <li>
                  <span class="check-text">
                    It helps in minimizing your out-of-the-pocket expenses with
                    a cashless medical treatment facility in the network
                    hospitals</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="col-md-6">
            <div class="type-inssrance">
              <ul>
                <li>
                  <span class="check-text">
                    Nowadays, amid the coronavirus outbreak, it is all the more
                    important to have or buy a medical insurance cover that
                    covers the cost of treatment including the cost of PPE kits,
                    masks, ventilators, ICU charges, etc.
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-6">
            <div class="type-inssrance">
              <ul>
                <li>
                  <span class="check-text">
                    Even those who are planning a family can buy a health
                    insurance plan to get maternity and newborn baby cover
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="col-md-6">
            <div class="type-inssrance">
              <ul>
                <li>
                  <span class="check-text">
                    You can buy Corona kavach and Corona Rakshak health plans if
                    you do not have coronavirus health insurance plans cover and
                    keep all your worries at bay
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-6">
            <div class="type-inssrance">
              <ul>
                <li>
                  <span class="check-text">
                    The cost of major surgeries like liver transplantation,
                    open-heart surgery, and day-care treatments like cataract
                    surgery, varicose veins, and the likewise are also paid by
                    the insurer if you have a medical insurance policy</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="col-md-6">
            <div class="type-inssrance">
              <ul>
                <li>
                  <span class="check-text">
                    A health insurance policy helps you and your family enjoy
                    their peace of mind without worrying about the future
                    hospitalization or a medical emergency, which can otherwise
                    drain a major portion of your savings</span
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-6">
            <div class="type-inssrance">
              <ul>
                <li>
                  <span class="check-text">
                    If you cannot pay much premium and are confused which health
                    insurance you should buy then you can opt for a standard
                    policy i.e. Arogya Sanjeevani health insurance policy, it
                    covers modern treatments and COVID-19 treatment as
                    well</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="customers-trust-types benefits-insurance">
      <div class="container">
        <div class="row">
          <div class="col-md-10 mx-auto">
            <div class="heading-steps-trust">
              <h3>Key Benefits of Health Insurance Plans</h3>
              <p>
                Comprehensive health insurance plans come packed with features
                that can assist a person in managing <br />
                expenses associated with medical emergencies and also with
                preventive health care check-ups.
              </p>
            </div>
          </div>
          <div class="col-md-10 mx-auto">
            <div class="services services--s2">
              <h2>
                Following are the key benefits of health insurance plans that
                one can consider:
              </h2>
              <div class="__inner">
                <div class="row justify-content-md-center no-gutters">
                  <div class="col-12 col-md-11 col-xl-10">
                    <div class="row justify-content-lg-center">
                      <div class="col-12 col-lg-6">
                        <div class="item">
                          <div class="d-sm-table">
                            <div class="">
                              <div class="icon-circle icon-box">
                                <img src="images/cashless.png" alt="" />
                              </div>
                            </div>
                            <div class="textbox align-middle">
                              <h4 class="title">Cashless Medical Treatment</h4>
                              <p v-if="readmore !== 10">
                                {{
                                  str10.length > 211
                                    ? str10.substring(0, 200) + "..."
                                    : str10
                                }}
                                <a
                                  v-if="str10.length > 211"
                                  href=""
                                  @click="readMore($event, 10)"
                                  >Know More</a
                                >
                              </p>
                              <p v-if="readmore === 10">
                                {{ str10 }}
                                <a href="" @click="readLess($event)"
                                  >Know Less</a
                                >
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-lg-6">
                        <div class="item">
                          <div class="d-sm-table">
                            <div class="">
                              <div class="icon-circle icon-box">
                                <img src="images/hospitalization.png" alt="" />
                              </div>
                            </div>
                            <div class="textbox align-middle">
                              <h4 class="title">
                                Coverage of Pre and Post-Hospitalization
                                Expenses
                              </h4>
                              <p v-if="readmore !== 11">
                                {{
                                  str11.length > 211
                                    ? str11.substring(0, 200) + "..."
                                    : str11
                                }}
                                <a
                                  v-if="str11.length > 211"
                                  href=""
                                  @click="readMore($event, 11)"
                                  >Know More</a
                                >
                              </p>
                              <p v-if="readmore === 11">
                                {{ str11 }}
                                <a href="" @click="readLess($event)"
                                  >Know Less</a
                                >
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-lg-center">
                      <div class="col-12 col-lg-6">
                        <div class="item">
                          <div class="d-sm-table">
                            <div class="">
                              <div class="icon-circle icon-box">
                                <img src="images/ambulance.png" alt="" />
                              </div>
                            </div>
                            <div class="textbox align-middle">
                              <h4 class="title">Ambulance Fee</h4>
                              <p>
                                Once hospitalized the person is free from the
                                burden of transportation fees as it is borne by
                                the insurer.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-lg-6">
                        <div class="item">
                          <div class="d-sm-table">
                            <div class="">
                              <div class="icon-circle icon-box">
                                <img src="images/claim.png" alt="" />
                              </div>
                            </div>
                            <div class="textbox align-middle">
                              <h4 class="title">No Claim Bonus NCB</h4>
                              <p v-if="readmore !== 13">
                                {{
                                  str13.length > 211
                                    ? str13.substring(0, 200) + "..."
                                    : str13
                                }}
                                <a
                                  v-if="str13.length > 211"
                                  href=""
                                  @click="readMore($event, 13)"
                                  >Know More</a
                                >
                              </p>
                              <p v-if="readmore === 13">
                                {{ str12 }}
                                <a href="" @click="readLess($event)"
                                  >Know Less</a
                                >
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-lg-center">
                      <div class="col-12 col-lg-6">
                        <div class="item">
                          <div class="d-sm-table">
                            <div class="">
                              <div class="icon-circle icon-box">
                                <img src="images/medical.png" alt="" />
                              </div>
                            </div>
                            <div class="textbox align-middle">
                              <h4 class="title">Medical Check-Up Facility</h4>
                              <p>
                                A medical plan entitles the insured to receive
                                regular medical check-ups. A free check-up
                                facility is provided by some insurers, or you
                                can get it as an add-on benefit.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-lg-6">
                        <div class="item">
                          <div class="d-sm-table">
                            <div class="">
                              <div class="icon-circle icon-box">
                                <img src="images/room.png" alt="" />
                              </div>
                            </div>
                            <div class="textbox align-middle">
                              <h4 class="title">Room Rent Sub-limits</h4>
                              <p v-if="readmore !== 12">
                                {{
                                  str12.length > 211
                                    ? str12.substring(0, 200) + "..."
                                    : str12
                                }}
                                <a
                                  v-if="str12.length > 211"
                                  href=""
                                  @click="readMore($event, 12)"
                                  >Know More</a
                                >
                              </p>
                              <p v-if="readmore === 12">
                                {{ str12 }}
                                <a href="" @click="readLess($event)"
                                  >Know Less</a
                                >
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="our-partners">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-10 mx-auto">
            <h3>Our Partners</h3>
            <div class="row">
              <div class="col-md-3">
                <div class="bank-list">
                  <img src="images/star-health-insurance.png" alt="" />
                  <h3>Star Health Insurance</h3>
                </div>
              </div>
              <div class="col-md-3">
                <div class="bank-list">
                  <img src="images/HDFC-Insurance.png" alt="" />
                  <h3>HDFC ERGO Health Insurance</h3>
                </div>
              </div>
              <div class="col-md-3">
                <div class="bank-list">
                  <img src="images/new-india.png" alt="" />
                  <h3>New india Assurance co Ltd</h3>
                </div>
              </div>
              <div class="col-md-3">
                <div class="bank-list">
                  <img src="images/bharti-axa.png" alt="" />
                  <h3>Bharti-Axa General Insurance co Ltd</h3>
                </div>
              </div>
              <div class="col-md-3">
                <div class="bank-list">
                  <img src="images/future-generali.png" alt="" />
                  <h3>Future Generali Insurance co, Ltd.</h3>
                </div>
              </div>
              <div class="col-md-3">
                <div class="bank-list">
                  <img src="images/sbi.png" alt="" />
                  <h3>SBI General Insurance Co Ltd.</h3>
                </div>
              </div>
              <div class="col-md-3">
                <div class="bank-list">
                  <img
                    src="images/go-digit-general-insurance-coltd.png"
                    alt=""
                  />
                  <h3>Go Digit General Insurance co. Ltd.</h3>
                </div>
              </div>
              <div class="col-md-3">
                <div class="bank-list">
                  <img src="images/reliance.png" alt="" />
                  <h3>Reliance General Insurance co ltd.</h3>
                </div>
              </div>
              <div class="col-md-3">
                <div class="bank-list">
                  <img src="images/liberty.png" alt="" />
                  <h3>Liberty General Insurance co ltd.</h3>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="view-all-blog">
                <a href="javascript:void(0)" @click="onClose(true)"
                  >Apply Now</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Inquiryformmodal
      InquiryType="INS"
      :showTitle="false"
      :showModal="showModal"
      :onClose="onClose"
    />
  </div>
</template>
<script>
import Inquiryformmodal from "./common/Inquiryformmodal";
export default {
  name: "Healthinsurance",
  components: { Inquiryformmodal },
  data() {
    return {
      showModal: false,
      readmore: 0,
      str1:
        "Individual health insurance plans offer insurance protection with benefits such as cashless hospitalisation, reimbursement, compensation for expenses incurred on pre and post hospitalization expenses, coverage for domiciliary treatment and many more. Individual health plans come with add-on covers as well to enhance the basic health insurance coverage, at aminimal premium.",
      str2:
        "Family health insurance offers insurance coverage to entire family against a single premium. Under this health plan, a defined sum insured is divided among the policy members equally, which can be availed either by one or all members of a family for one or more claims during the tenure of the policy. With a family health plan, almost all the family members can be added under a single health insurance premium.",
      str3:
        "Senior Citizen health insurance plans offer insurance coverage to the age group between 60 and above. The health insurance plan covers hospitalization expenses including in-patient expenses, OPD expenses, Daycare procedures, pre, and post-hospitalisation expenses along with tax deduction benefit u/s 80 D.",
      str4:
        "Critical illness health insurance plans offer a lump sum amount in case the insured is diagnosed with a critical illness such as kidney failure, paralysis, cancer, heart attack, etc. Usually brought as a standalone policy or as a rider, the sum insured is pre-defined, where the insured has to survive a particular survival period after being diagnosed to avail the policy benefits.",
      str5:
        "The maternity health insurance plans offer coverage for maternity expenses incurred during both pre and post-natal care, child delivery (normal or cesarean). Some providers also include expenses incurred on vaccination of newborn babies in a maternity health insurance plan. The list of coverage also includes the transportation fee for ferrying the mom-to-be to the nearest network hospital of her choice.",
      str6:
        "Personal Accident Insurance is a rider cover that offers insurance coverage in case of an accident leading to disability or death. The policy coverage includes hospitalization and bears the medical outlay in the event of an accident. A fixed monetary benefit is offered in case of an unfortunate event leading to loss of income.",
      str7:
        "More than 80% of employers these days provide health coverage to their employees. The health insurance offered by an employer covers hospitalization expenses of the employee and his/her family including spouse, children or parents. It is a wise decision to opt for the mediclaim offered by your company as you need not pay any premium. This comes under a group health insurance policy and the premium is paid by the employer, based on the group size and the benefits offered",
      str8:
        "Post COVID-19 outbreak, the IRDAI has also launched two Coronavirus specific health insurance plans i.e. Corona Kavach health plan and Corona Rakshak health insurance plan. Corona kavach is a family floater plan while Corona Rakshak is an individual coverage based plan. Both the polices cover COVID-19 hospitalization expenses, including the cost of consumable items like masks, gloves, PPE kits, oximeters, ventilators, etc. that make the major portion of the hospital bills. If someone has a health insurance policy then also any of these coronavirus health insurance policies can be purchased to ensure safety of your loved ones during the ongoing pandemic.",
      str9:
        "Unit- Linked Health Insurance Plan (ULHP) is a type of health plan, which has been introduced recently. Unit Linked Health  Insurance plans offer a unique combination of health insurance and investment. Apart from giving health protection, ULHPs also contribute to building a corpus that can be used to meet expenditures that are not covered by health insurance plans.",
      str10:
        "Every medical insurance company has tie-ups with various nursing homes and hospitals across the country called 'empanelled hospitals'. If you are admitted to one of these, you don’t need to pay anything. You only need to mention your policy number and everything else will be taken care of by the hospital and your insurer. These types of health insurance plans are preferred because there is no stress of claim reimbursement and documentation. However, if your expenses go beyond the sub-limits specified by the insurance cover or marked as not covered by the provider, then you will have to settle it directly with the hospital. Another important thing to remember is that cashless mediclaim is not available if one gets hospitalized which is not a part of the hospital network of the insurance company.",
      str11:
        "This feature of a health insurance policy takes care of expenses incurred on both pre and post-hospitalisation. It takes into account the costs incurred during a certain number of days both prior to and post hospitalization as part of the claim, provided the expenditures are related to the covered disease/illness.",
      str12:
        "In Your Health Insurance Plan A health insurance plan may have various sub-limits associated with it; room rent is one of those sub-limits. General Insurance Companies provide you with maximum coverage up to the sum assured. However, they can deliberately trim down their liability by introducing the sub-limit clause in the coverage for hospital room rent. Once the insured is hospitalized the sub-limit on room rent coverage is applicable on a per day basis",
        str13:"No Claim Bonus is a bonus provided to the  insured if no claim has been filed for any treatment in the previous policy year. The reward can be offered either as an increment in the sum assured or as a discount on the premium cost. You can avail this advantage on policy renewal."
    };
  },
  methods: {
    onClose: function (status) {
      this.showModal = status;
    },
    readMore: function (e, num) {
      e.preventDefault();
      this.readmore = num;
    },
    readLess: function (e) {
      e.preventDefault();
      this.readmore = 0;
    },
  },
};
</script>