import Vue from 'vue';
import VueRouter from "vue-router";
import { BootstrapVue } from 'bootstrap-vue'
import App from './App.vue';
import Home from "./components/Home.vue";
import Singleproduct from "./components/Singleproduct.vue";
import Searchresults from "./components/Searchresults.vue";
import Carloan from "./components/Carloan";
import Usedcarloan from "./components/Usedcarloan";
import Carinsurance from "./components/Carinsurance";
import Healthinsurance from "./components/Healthinsurance";
import Otherinsurance from "./components/Otherinsurance";
import Homeloans from "./components/Homeloans";
import Contactus from "./components/Contactus";
import Helpsupport from "./components/Helpsupport";
import Sellcar from "./components/Sellcar";
import Aboutus from "./components/Aboutus";
import Career from "./components/Career";
import Becomedealer from "./components/Becomedealer";
import Buyusedcar from "./components/Buyusedcar";
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.config.productionTip = false
const $ = window.jQuery;
const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      component: Home
    },
    {
      path: "/home",
      component: Home
    },
    {
      path: "/details",
      component: Singleproduct
    },
    {
      path: "/search",
      component: Searchresults
    },
    {
      path: "/new-car-loan",
      component: Carloan
    },
    {
      path: "/used-car-loan",
      component: Usedcarloan
    },
    {
      path: "/car-insurance",
      component: Carinsurance
    },
    {
      path: "/health-insurance",
      component: Healthinsurance
    },
    {
      path: "/other-insurance",
      component: Otherinsurance
    },
    {
      path: "/home-loans",
      component: Homeloans
    },
    {
      path: "/contact-us",
      component: Contactus
    },
    {
      path: "/help-support",
      component: Helpsupport
    },
    {
      path: "/sell-car",
      component: Sellcar
    },
    {
      path: "/about-us",
      component: Aboutus
    },
    {
      path: "/career",
      component: Career
    },
    {
      path: "/become-dealer",
      component: Becomedealer
    },
    {
      path: "/buy-used-car",
      component: Buyusedcar
    }
  ]
});
router.beforeEach((to, from, next) => {
  $('.navbar-collapse').collapse('hide');
  next();
})
Vue.filter('fuelType', function (text) {
  var type = text;
  if (text == "DL") {
    type = "Diesel";
  }
  if (text == "HY") {
    type = "Hybrid";
  }
  if (text == "EL") {
    type = "Electric";
  }
  if (text == "LP") {
    type = "LPG";
  }
  if (text == "CN") {
    type = "CNG";
  }
  if (text == "PT") {
    type = "Petrol";
  }
  return type;
});
Vue.filter('bodyType', function (text) {
  var type = text;
  if (text == "HB") {
    type = "Hatchback";
  }
  if (text == "SD") {
    type = "Sedan";
  }
  if (text == "SU") {
    type = "SUV";
  }
  if (text == "MU") {
    type = "MUV";
  }
  if (text == "CP") {
    type = "Coupe";
  }
  if (text == "SALOON") {
    type = "SALOON";
  }
  if (text == "TRACTOR") {
    type = "TRACTOR";
  }
  if (text == "PICKUP") {
    type = "PICKUP";
  }
  return type;
});
Vue.component("modalx", {
  props: ['title'],
  template: `
		<div class="modal fade in modal-active">
			<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
							<button type="button" @click="$emit('close')" class="close"><span >&times;</span></button>
							<h4 class="modal-title">
								{{title}}
							</h4>
						</div>
						<div class="modal-body">
								<slot></slot>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-default" @click="$emit('close')">Close</button>
							<button type="button" class="btn btn-primary">Save changes</button>
						</div>
				</div>
			</div>
		</div>`
});
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
