<template>
  <div class="fixed-top navbar navbar-light bg-light navbar-expand-md">
    <div class="container">
      <button
        type="button"
        class="navbar-toggler"
        data-toggle="collapse"
        data-target=".navbar-collapse"
      >
        ☰
      </button>
      <div class="navbar-collapse collapse">
        <div class="col-md-9">
          <ul class="nav navbar-nav">
            <li class="dropdown menu-large nav-item">
              <a
                href="javascript:void(0)"
                class="dropdown-toggle nav-link"
                data-toggle="dropdown"
              >
                Car Loan
              </a>

              <ul class="dropdown-menu megamenu menusmall">
                <div class="row">
                  <li class="col-md-12 dropdown-item">
                    <ul>
                      <li>
                        <router-link to="/new-car-loan"
                          >New Car Loan</router-link
                        >
                      </li>
                      <li class="disabled">
                        <router-link to="/used-car-loan"
                          >Used Car Loan</router-link
                        >
                      </li>
                    </ul>
                  </li>
                </div>
              </ul>
            </li>
            <li class="nav-item">
              <router-link to="/buy-used-car" class="nav-link"
                >Buy a used Car</router-link
              >
            </li>
            <!-- <li class="dropdown menu-large nav-item">
            <a
              href="javascript:void(0)"
              class="dropdown-toggle nav-link"
              data-toggle="dropdown"
              >Used Car Purchase
            </a>
            <ul class="dropdown-menu megamenu">
              <div class="row">
                <li class="col-md-3 dropdown-item">
                  <ul>
                    <li class="dropdown-header">Glyphicons</li>
                    <li><a href="javascript:void(0)">Available glyphs</a></li>
                    <li class="disabled"><a href="#">How to use</a></li>
                    <li><a href="javascript:void(0)">Examples</a></li>
                    <li class="divider"></li>
                    <li class="dropdown-header">Dropdowns</li>
                    <li><a href="javascript:void(0)">Example</a></li>
                    <li>
                      <a href="javascript:void(0)">Aligninment options</a>
                    </li>
                    <li><a href="javascript:void(0)">Headers</a></li>
                    <li>
                      <a href="javascript:void(0)">Disabled menu items</a>
                    </li>
                  </ul>
                </li>
                <li class="col-md-3 dropdown-item">
                  <ul>
                    <li class="dropdown-header">Button groups</li>
                    <li><a href="javascript:void(0)">Basic example</a></li>
                    <li><a href="javascript:void(0)">Button toolbar</a></li>
                    <li><a href="javascript:void(0)">Sizing</a></li>
                    <li><a href="javascript:void(0)">Nesting</a></li>
                    <li><a href="javascript:void(0)">Vertical variation</a></li>
                    <li class="divider"></li>
                    <li class="dropdown-header">Button dropdowns</li>
                    <li>
                      <a href="javascript:void(0)">Single button dropdowns</a>
                    </li>
                  </ul>
                </li>
                <li class="col-md-3 dropdown-item">
                  <ul>
                    <li class="dropdown-header">Input groups</li>
                    <li><a href="javascript:void(0)">Basic example</a></li>
                    <li><a href="javascript:void(0)">Sizing</a></li>
                    <li>
                      <a href="javascript:void(0)"
                        >Checkboxes and radio addons</a
                      >
                    </li>
                    <li class="divider"></li>
                    <li class="dropdown-header">Navs</li>
                    <li><a href="javascript:void(0)">Tabs</a></li>
                    <li><a href="javascript:void(0)">Pills</a></li>
                    <li><a href="javascript:void(0)">Justified</a></li>
                  </ul>
                </li>
                <li class="col-md-3 dropdown-item">
                  <ul>
                    <li class="dropdown-header">Navbar</li>
                    <li><a href="javascript:void(0)">Default navbar</a></li>
                    <li><a href="javascript:void(0)">Buttons</a></li>
                    <li><a href="javascript:void(0)">Text</a></li>
                    <li><a href="#">Non-nav links</a></li>
                    <li>
                      <a href="javascript:void(0)">Component alignment</a>
                    </li>
                    <li><a href="javascript:void(0)">Fixed to top</a></li>
                    <li><a href="javascript:void(0)">Fixed to bottom</a></li>
                    <li><a href="javascript:void(0)">Static top</a></li>
                    <li><a href="javascript:void(0)">Inverted navbar</a></li>
                  </ul>
                </li>
              </div>
            </ul> 
          </li> -->
            <li class="nav-item">
              <router-link to="/sell-car" class="nav-link"
                >Sell your car</router-link
              >
            </li>

            <li class="dropdown menu-large nav-item">
              <a
                href="javascript:void(0)"
                class="dropdown-toggle nav-link"
                data-toggle="dropdown"
                >Insurance
              </a>

              <ul class="dropdown-menu megamenu menusmall car-insurance">
                <div class="row">
                  <li class="col-md-12 dropdown-item">
                    <ul>
                      <li>
                        <router-link to="/car-insurance"
                          >Car Insurance</router-link
                        >
                      </li>
                      <li class="disabled">
                        <router-link to="/health-insurance"
                          >Health Insurance</router-link
                        >
                      </li>
                      <li class="disabled">
                        <router-link to="/other-insurance"
                          >Other Insurance</router-link
                        >
                      </li>
                    </ul>
                  </li>
                </div>
              </ul>
            </li>
            <li class="nav-item">
              <router-link to="/home-loans" class="nav-link"
                >Home Loan</router-link
              >
            </li>
            <li class="nav-item">
              <a href="javascript:void(0)" class="nav-link"
                >Loan Against Property</a
              >
            </li>
          </ul>
        </div>
        <div class="col-md-3">
          <ul class="navbar-nav nav-flex-icons">
            <li class="nav-item line-right">
              <router-link to="/help-support" class="nav-link"
                >Help & Support</router-link
              >
            </li>
            <li class="dropdown menu-large nav-item">
              <router-link
                to="/about-us"
                class="dropdown-toggle nav-link"
                data-toggle="dropdown"
                >About Us</router-link
              >

              <ul class="dropdown-menu megamenu menusmall about-us-nemu">
                <div class="row">
                  <li class="col-md-12 dropdown-item">
                    <ul>
                      <li>
                        <router-link to="/contact-us">Contact Us</router-link>
                      </li>
                    </ul>
                  </li>
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Menu",
  props: {
    msg: String,
  },
};
</script>