<template>
  <section class="Services-sec">
    <div class="container">
      <h3>Services</h3>
      <div class="row">
        <div class="col-md-4" v-for="(serv, index) in services" :key="index">
        
          <div class="service-block">
            <img :src="serv.image" alt="" />
            <div class="services-text ser-text">
              <h3>{{ serv.name }}</h3>
            </div>
          </div>
        
        </div>
        <!-- <div class="col-md-4">
          <div class="service-block">
            <img src="images/purchase.png" alt="" />
            <div class="services-text ser-text">
              <h3>Used Car Purchase</h3>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="service-block">
            <img src="images/sale.png" alt="" />
            <div class="services-text ser-text">
              <h3>Used Car Sale</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="row bottom-section">
        <div class="col-md-4">
          <div class="service-block">
            <img src="images/Insurance.png" alt="" />
            <div class="services-text ser-text">
              <h3>Insurance</h3>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="service-block">
            <img src="images/home-loan.png" alt="" />
            <div class="services-text ser-text">
              <h3>Used Car Purchase</h3>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="service-block">
            <img src="images/loan.png" alt="" />
            <div class="services-text ser-text">
              <h3>Loan Against Property</h3>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Services",
  props: {
    msg: String,
    services: Array,
  },
};
</script>