<template>
  <section class="found-section">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <h3 class="fount-text">{{items.length}} Results Found</h3>
        </div>
        <div class="col-md-4 main-sort-sec">
          <div class="sort-by-sec">
            <a
              href="javascript:void(0)"
              v-bind:class="{ active: viewStyle === 'grid' }"
              @click="changeViewStyle('grid')"
            >
              <i class="fa fa-th-large" aria-hidden="true"></i>
            </a>
            <a
              href="javascript:void(0)"
              v-bind:class="{ active: viewStyle === 'list' }"
              @click="changeViewStyle('list')"
            >
              <i class="fa fa-list" aria-hidden="true"></i>
            </a>
          </div>
          <!-- <div class="form-group sortby">
            <select class="form-control" id="sel1">
              <option>Sort By</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
            </select>
          </div> -->
        </div>
      </div>
      <div class="col-md-12">
        <gridview
          :items="items"
          v-if="viewStyle === 'grid'"
          :viewSingle="viewSingle"
        />
        <listview
          :items="items"
          v-if="viewStyle === 'list'"
          :viewSingle="viewSingle"
        />
        <searchsidebar />
      </div>
      <div class="col-md-12">
        <ul class="pagination">
          <li class="page-item active">
            <a
              href="javascript:void(0)"
              :class="page > 1?'page-link ':'page-link pointer-events-none'"
              @click="changePage('back')"
            >
              <i class="fa fa-angle-left" aria-hidden="true"></i>
            </a>
          </li><li class="page-item"> <a href="javascript:void(0)"></a></li>
          <li class="page-item active">
            <a
              href="javascript:void(0)"
              @click="changePage('next')"
              :class="hasMore?'page-link ':'page-link pointer-events-none'"
              ><i class="fa fa-angle-right" aria-hidden="true"></i
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import Gridview from "./Gridview.vue";
import Listview from "./Listview.vue";
import Searchsidebar from "./Searchsidebar.vue";
export default {
  components: { Searchsidebar, Gridview, Listview },
  name: "Results",
  props: {
    items: Array,
    searchItems: Function,
    page: Number,
    changePage: Function,
    hasMore: Boolean,
  },
  data() {
    return {
      viewStyle: "grid",
    };
  },
  methods: {
    changeViewStyle(style) {
      this.viewStyle = style;
    },
    viewSingle: function (val) {
      sessionStorage.setItem("single_car", JSON.stringify(val));
      this.$router.push("/details");
    },
  },
};
</script>