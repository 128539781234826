<template>
   <div>
      <section class="about-us-sec dealer_bg">
         <div class="container">
            <div class="col-md-12">
               <div class="sell-car-text text-change-career">
                  <h3>Become a Dealer</h3>
                
               </div>
            </div>
         </div>
      </section>
      <section class="about_us_content" id="career-text">
          <div class="container">
              <div class="col-md-12">
                  <div class="contant-about-innner">
                     <h3> If you are already into this business and want to earn and be a part of this growth, this is the right place to be part of</h3>

             
              </div>
              <div class="help-form">
                    <div class="row form-start-help">
                
               
                  <div class="col-md-6"> 
                            <div class="form-group">
                            
                                <input type="text" class="form-control" placeholder="Name" id="name" />
                                <span class="user_icons"><i class="fa fa-user" aria-hidden="true"></i></span>
                            </div>
                  </div>
                   <div class="col-md-6"> 
                            <div class="form-group">
                            
                                <input type="text" class="form-control" placeholder="Location" id="name" />
                                <span class="user_icons"><i class="fa fa-map-marker" aria-hidden="true"></i></span>
                            </div>
                  </div>
                     <div class="col-md-6"> 
                            <div class="form-group">
                            
                                <input type="tel" id="phone" name="phone" placeholder="Mobile Number" />
                                 <span class="user_icons"><i class="fa fa-mobile" aria-hidden="true"></i></span>
                            </div>
                  </div>
                   <div class="col-md-6"> 
                            <div class="form-group">
                            
                                <input type="email" class="form-control" placeholder="Email" id="name" />
                                 <span class="user_icons"><i class="fa fa-envelope" aria-hidden="true"></i></span>
                            </div>
                  </div>
                
                       <div class="col-md-12">
<div class="form-group">

  <textarea class="form-control" rows="5" id="comment" placeholder="Massage:"></textarea>
</div>
                       </div>
                <div class="col-md-12">
<div class="btn-section"><button type="button" class="btn btn-primary sumbit-btn"> Submit </button></div>
                </div>
                </div>
              </div>
          </div>
          </div>
      </section>
    
   </div>
</template>
<script>
   export default{
       name:"Becomedealer"
   }
    
</script>