    <template>
  <section class="related-cars">
    <div class="container">
      <div class="">
        <h3>Related Cars</h3>
        <vue-slick-carousel class="related-cars-section" v-bind="crouSettings">
          <div
            class="col-md-12"
            v-for="(car, index) in related_cars"
            :key="index"
          >
            <div class="slider-image" v-on:click="viewSingle(car)">
              <img
                :src="'https://www.justacar.in/lap' + car.photos[0].url"
                class="d-inline-block align-top"
                alt=""
              />
              <div class="banner-text-brand">
                <div class="banner-text-iner">
                  <h4>
                    {{
                      car.make.toUpperCase() +
                      " " +
                      car.model +
                      " " +
                      car.modelvariant
                    }}
                  </h4>
                  <span>{{ new Date(car.mfgyear).getFullYear() }}</span>
                   <h5 class="diesel-sec">{{ car.fueltype | fuelType }}</h5>
                </div>
                <div class="banner-text-right">
                 
                  <span class="price" v-if="car.price">&#8377;{{car.price}}</span>
                </div>
              </div>
            </div>
          </div>
        </vue-slick-carousel>
        <div class="view-more">
          <a href="javascript:void(0)">View All</a>
        </div>
      </div>
    </div>
  </section>
</template>
    <script>
import VueSlickCarousel from "vue-slick-carousel";
export default {
  name: "Relatedcars",
  components: { VueSlickCarousel },
  props: {
    related_cars: Array,
  },
  data() {
    return {
      crouSettings: {
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        arrows: true,
        prevArrow:
          '<div class="slick-prev"><i class="i-prev"></i><span class="sr-only sr-only-focusable"></span></div>',
        nextArrow:
          '<div class="slick-next"><i class="i-next"></i><span class="sr-only sr-only-focusable"></span></div>',
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  methods: {
    viewSingle: function (val) {
      sessionStorage.setItem("single_car", JSON.stringify(val));
      // singleCarDetails.$emit('single_car_updated');
      window.location.reload();
    },
  },
};
</script>