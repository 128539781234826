<template>
  <section class="most-popular-brand">
    <div class="container">
      <div class="main-slider-section win-deals" v-if="brands.length>0">
        <h3>Our Most Popular Brands</h3>
        <VueSlickCarousel
          class="row slider-brands"
          v-bind="popularBrandsSettings"
        >
          <div class="col-md-12" v-for="(brand, index) in brands" :key="index">
            <div class="brands-sec">
              <img :src="brand.logobig" :atl="brand.name" />
            </div>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </section>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
export default {
  name: "Popularbrands",
  components: { VueSlickCarousel },
  props: {
    msg: String,
    brands: Array,
  },
  data() {
    return {
      popularBrandsSettings: {
        arrows: true,
        dots: false,
        infinite: false,
        speed: 300,
        autoplay: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        prevArrow:
          '<div class="slick-prev"><i class="i-prev"></i><span class="sr-only sr-only-focusable"></span></div>',
        nextArrow:
          '<div class="slick-next"><i class="i-next"></i><span class="sr-only sr-only-focusable"></span></div>',
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
        autoplay: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
        autoplay: true,
            },
          },
        ],
      },
    };
  },
};
</script>