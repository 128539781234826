<template>
  <div>
 <div class="carloan-section helpsupport">
         <div class="container-fluid">
            <div class="car-loan-heading">
               <h2>Help & Support</h2>
              
            </div>
         </div>
      </div>
      <section class="help-form">
          <div class="container">
               <h4>Need help or support?</h4>
<p>If you have any questions or need support for Car Loan , Insurance and Home loan , you can contact us at contact@justacar.in</p>
                <div class="row form-start-help">
                
                  <div class="col-md-6"> 
                        <div class="form-group">
                       
                        <select class="form-control" id="sel1"> 
                            <option>New car: </option>

                            <option>Insurance  /Claim </option>
                            <option> Home Loan</option>
                            <option>Loan Against property</option>
                            <option> Used car Sale  </option>
                            <option> Used car Purcase      </option>
                            <option>Feed back and suggestion </option>
                        
                        </select>
                        </div>
                  </div>
                  <div class="col-md-6"> 
                            <div class="form-group">
                            
                                <input type="text" class="form-control" placeholder="Enter Name" id="name" />
                                <span class="user_icons"><i class="fa fa-user" aria-hidden="true"></i></span>
                            </div>
                  </div>
                   <div class="col-md-6"> 
                            <div class="form-group">
                            
                                <input type="email" class="form-control" placeholder="Enter Email" id="name" />
                                 <span class="user_icons"><i class="fa fa-envelope" aria-hidden="true"></i></span>
                            </div>
                  </div>
                   <div class="col-md-6"> 
                            <div class="form-group">
                            
                                <input type="tel" id="phone" name="phone" placeholder="Enter Mobile Number" />
                                 <span class="user_icons"><i class="fa fa-mobile" aria-hidden="true"></i></span>
                            </div>
                  </div>
                   <div class="col-md-12">
<div class="form-group">

  <textarea class="form-control" rows="5" id="comment" placeholder="Massage:"></textarea>
</div>
                </div>
                <div class="col-md-12">
<div class="btn-section"><button type="button" class="btn btn-primary sumbit-btn"> Submit </button></div>
                </div>
                </div>
               
                
              </div>
      </section>
      </div>
      </template>
      <script>
export default{
    name:"Helpsupport"
}
 </script>
