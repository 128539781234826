<template>
  <div>
    <div class="carloan-section carinssurancebg">
      <div class="container-fluid">
        <div class="car-loan-heading">
          <h2>Car Insurance</h2>
          <p>
            Car Insurance is a type of vehicle insurance policy that protects
            your car & car owner from any risks & <br />
            damages that may lead to financial losses. It is a contract between
            the motor vehicle company & a car <br />
            owner that provides on-road protection against any loss or damages
            arising due to an accident. It <br />
            provides financial coverage to the beneficiary of the car policy
            against any loss or damages arising out of <br />
            road accidents, third party liabilities, theft, man made calamities,
            fire or natural disaster.
          </p>
          <div class="view-all-blog">
            <a href="javascript:void(0)" @click="onClose(true)">Apply Now</a>
          </div>
        </div>
      </div>
    </div>
    <section class="here-tip-sec">
      <div class="container-fluid">
        <div class="col-md-12">
          <div class="heading-steps inssurance-tex">
            <h4>Buy/Renew Car Insurance</h4>
            <p>
              Buying a four wheeler insurance policy is mandatory in India for
              all cars as per the Motor Vehicle Act.Vehicle insurance <br />
              companies compensate for the loss or damage caused to the insured
              vehicle and a third-party from the insured <br />
              four-wheeler. Here are some of the reasons to buy a new car
              insurance policy in India: <br />
            </p>
          </div>
        </div>
        <div class="col-md-12">
          <div class="col-md-6">
            <div class="type-inssrance">
              <ul>
                <li>
                  <span class="check-text"
                    >It pays for the car damages as a result of a collision,
                    accident, death or natural calamities, which otherwise would
                    need to be paid by the insured</span
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-6">
            <div class="type-inssrance">
              <ul>
                <li>
                  <span class="check-text">
                    It pays for hospitalization expenses in case of an
                    accident</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="col-md-6">
            <div class="type-inssrance">
              <ul>
                <li>
                  <span class="check-text">
                    It reduces the financial and legal damages that can arise
                    from a third-party liability or damage</span
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-6">
            <div class="type-inssrance">
              <ul>
                <li>
                  <span class="check-text">
                    With rider benefits like roadside assistance, zero
                    depreciation the expenses are further reduced</span
                  >
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="view-all-blog">
            <a href="javascript:void(0)">Apply Now</a>
          </div> -->
        </div>
      </div>
    </section>

    <section class="types-policy">
      <div class="container">
        <div class="col-md-12">
          <div class="heading-steps inssurance-tex">
            <h4>Types Of Car Insurance Policy In India</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 mx-auto">
            <div class="col-lg-4 col-md-6">
              <div class="single-work mt-0 text-center">
                <div class="work-icon">
                  <img class="" src="images/Insurance-policy.png" alt="icon" />
                </div>
                <h5>Comprehensive Car Insurance Policy</h5>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-work mt-0 text-center">
                <div class="work-icon">
                  <img class="" src="images/third-pary.png" alt="icon" />
                </div>
                <h5>Third Party Policy</h5>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-work mt-0 text-center">
                <div class="work-icon">
                  <img class="" src="images/income.png" alt="icon" />
                </div>
                <h5>Pay As you drive Policy</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="add-on-covers">
      <div class="container">
        <div class="col-md-12">
          <div class="view-all-blog single-btn">
            <a href="javascript:void(0)">Want to Know more </a>
          </div>
          <div class="col-md-6">
            <div class="cover-text">
              <h3>Add On Covers In Car Insurance Policy</h3>
              <ul>
                <li><span> No Claim Bonus Protection (NCB)</span></li>
                <li><span> Zero Depreciation Cover</span></li>
                <li><span> Consumable Cover</span></li>
                <li><span> Engine Protection Cover</span></li>
                <li><span> Key Protection Cover</span></li>
                <li><span> Personal Accident Rider Benefit</span></li>
                <li><span> Cover For Car Accessories</span></li>
                <li><span> Personal Accident Cover</span></li>
                <li><span> Loss or Damage oF the Insured Vehicle</span></li>
                <li><span> Liability of Third Party</span></li>
              </ul>
            </div>
          </div>
          <div class="col-md-6">
            <div class="cover-text">
              <h3>What Is Not Covered Under The Policy</h3>
              <ul>
                <li>
                  <span>
                    Loss Or Damage due to Gradual Wear And tear or car or its
                    parts</span
                  >
                </li>
                <li><span>Loss or Damage , if policy is not in force</span></li>
                <li>
                  <span>
                    Loss or damage of the vehicle due to intoxication or drugs,
                    alcohol etc.</span
                  >
                </li>
                <li>
                  <span
                    >If policy and Registration are not on the same name(
                    conditions Apply)</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="view-all-blog">
            <a href="javascript:void(0)" @click="onClose(true)">Apply Now</a>
          </div>
        </div>
      </div>
    </section>
    <section class="customers-trust-types whyussection">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-10 mx-auto">
            <div class="heading-steps-trust">
              <h3>Why US</h3>
            </div>
            <div class="col-md-3">
              <div class="inner-trust-sec">
                <div class="img-seccar">
                  <img src="images/staff.png" alt="" />
                </div>
                <div class="customer-trust">
                  <div class="cHead">
                    Dedicated Team of staff for Renewal/Claim Help
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="inner-trust-sec">
                <div class="img-seccar">
                  <img src="images/disscount.png" alt="" />
                </div>
                <div class="customer-trust">
                  <div class="cHead">
                    Discount Upto 80% on your Car Insurance
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="inner-trust-sec">
                <div class="img-seccar">
                  <img src="images/prmote-services.png" alt="" />
                </div>
                <div class="customer-trust">
                  <div class="cHead">Prompt Service</div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="inner-trust-sec">
                <div class="img-seccar">
                  <img src="images/reminder.png" alt="" />
                </div>
                <div class="customer-trust">
                  <div class="cHead">Renewal reminders</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 mx-auto">
            <div class="col-md-3">
              <div class="inner-trust-sec">
                <div class="img-seccar">
                  <img src="images/easy2.png" alt="" />
                </div>
                <div class="customer-trust">
                  <div class="cHead">Easy Endorsments</div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="inner-trust-sec">
                <div class="img-seccar">
                  <img src="images/document.png" alt="" />
                </div>
                <div class="customer-trust">
                  <div class="cHead">Soft Copy OF documents</div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="inner-trust-sec">
                <div class="img-seccar">
                  <img src="images/easy.png" alt="" />
                </div>
                <div class="customer-trust">
                  <div class="cHead">
                    Easy to choose from different insurance Companies to Choose
                    from
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="view-all-blog">
              <a href="javascript:void(0)" @click="onClose(true)">Apply Now</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="our-partners">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-10 mx-auto">
            <h3>Our Partners</h3>

            <div class="row">
              <div class="col-md-3">
                <div class="bank-list">
                  <img src="images/new-india.png" alt="" />
                  <h3>New india Assurance co Ltd</h3>
                </div>
              </div>
              <div class="col-md-3">
                <div class="bank-list">
                  <img src="images/bharti-axa.png" alt="" />
                  <h3>Bharti-Axa General Insurance co Ltd</h3>
                </div>
              </div>
              <div class="col-md-3">
                <div class="bank-list">
                  <img src="images/future-generali.png" alt="" />
                  <h3>Future Generali Insurance co, Ltd.</h3>
                </div>
              </div>
              <div class="col-md-3">
                <div class="bank-list">
                  <img src="images/sbi.png" alt="" />
                  <h3>SBI General Insurance Co Ltd.</h3>
                </div>
              </div>
              <div class="col-md-3">
                <div class="bank-list">
                  <img src="images/go-digit-general.png" alt="" />
                  <h3>Go Digit General Insurance co. Ltd.</h3>
                </div>
              </div>
              <div class="col-md-3">
                <div class="bank-list">
                  <img src="images/reliance.png" alt="" />
                  <h3>Reliance General Insurance co ltd.</h3>
                </div>
              </div>
              <div class="col-md-3">
                <div class="bank-list">
                  <img src="images/liberty.png" alt="" />
                  <h3>Liberty General Insurance co ltd</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Inquiryformmodal
      InquiryType="INS"
      :showTitle="false"
      :showModal="showModal"
      :onClose="onClose"
    />
  </div>
</template>
<script>
import Inquiryformmodal from "./common/Inquiryformmodal";
export default {
  name: "Carinsurance",
  components: { Inquiryformmodal },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    onClose: function (status) {
      this.showModal = status;
    },
  },
};
</script>