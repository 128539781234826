<template>
  <div>
    <div class="carloan-section usedcasloan-bg">
      <div class="container-fluid">
        <div class="car-loan-heading">
          <h2>Used Car Loan</h2>
          <p>
            A good second-hand car can be bought with the help of a used car
            loan. Used car loans are provided at <br />
            attractive interest rates and come with a repayment tenure ofup to
            exit age of 12 years of vehicle . <br />
            Certain lenders provide loans of up to 166% of the car’s
            value(condition Apply). <br />
            Most banks and NBFCs provide used car loans. Self-employed
            individuals and salaried <br />
            employees can avail a used car loan.
          </p>
          <div class="view-all-blog">
            <a href="javascript:void(0)" @click="onClose(true)">Apply Now</a>
          </div>
        </div>
      </div>
    </div>
    <section class="here-tip-sec add-carloan">
      <div class="container-fluid">
        <div class="col-md-12">
          <div class="col-md-6">
            <div class="image-carloan">
              <img src="images/car-loan2.jpg" alt="" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="heading-steps tip-tex">
              <h4>Here's a Tip!</h4>
              <p>
                It is vital that you maintain a good credit score when you apply
                for a car loan. Apart from the loan getting approved quicker,
                lenders will offer low interest rates if your credit score is
                good. No security or collateral is required when availing a car
                loan. The car acts as the security.
              </p>
              <div class="view-all-blog">
                <a href="javascript:void(0)" @click="onClose(true)">Apply Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="steps-loan-types">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-10 mx-auto">
            <div class="heading-steps textcar-loan">
              <h4>Eligibility Criteria and Documents Required</h4>
              <p>
                The
                <a href="javascript:volid(0)">car loan eligibility</a> criteria
                can be different for different banks. The common criteria are as
                follows:
              </p>
              <p>
                Age between 18 years and 75 years, Some bank Offer loans to
                higher age person also
              </p>
              <div class="row">
                <div class="col-md-4">
                  <div class="min-steps-sec">
                    <img src="images/identity-proof.png" alt="" />
                    <h5>Identity proof (any of the following)</h5>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >Aadhaar
                    </p>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >Passport
                    </p>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >Driving license
                    </p>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >Voters ID card
                    </p>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >PAN card
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="min-steps-sec">
                    <img src="images/address-proof.png" alt="" />
                    <h5>Address proof (any of the following)</h5>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >Aadhaar
                    </p>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >Passport
                    </p>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >Driving license
                    </p>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >Ration card
                    </p>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >Utility bills
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="min-steps-sec">
                    <img src="images/income.png" alt="" />
                    <h5>Proof of income (Any or Some of the following)</h5>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >Form 16
                    </p>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >Salary slips if you are salaried
                    </p>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >Latest Income Tax Returns
                    </p>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >Bank statements going back 6 months
                    </p>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >Fard, If Agriculturist
                    </p>
                    <p>
                      <span class="checkicon"
                        ><i class="fa fa-check" aria-hidden="true"></i></span
                      >Electricity Bill, in the name of family memberss
                    </p>
                  </div>
                </div>
              </div>
              <p class="bottom-text">
                To prove your eligibility, you’ll need to provide certain
                documents. <br />
                Though this too is specific to different lenders, the common
                documents will be:
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="customers-trust-types">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-10 mx-auto">
            <div class="heading-steps-trust">
              <h3>Why Justacar.in</h3>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="inner-trust-sec">
                  <div class="img-seccar">
                    <img src="images/help.png" alt="" />
                  </div>
                  <div class="customer-trust">
                    <div class="cHead">
                      We Help you in deciding the Right car for your need
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="inner-trust-sec">
                  <div class="img-seccar">
                    <img src="images/dealers.png" alt="" />
                  </div>
                  <div class="customer-trust">
                    <div class="cHead">
                      We help you to get the the best deal on your dream car
                      from the different dealers
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="inner-trust-sec">
                  <div class="img-seccar">
                    <img src="images/home-services.png" alt="" />
                  </div>
                  <div class="customer-trust">
                    <div class="cHead">
                      We provide the door step service at the comfort of your
                      home/office
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="inner-trust-sec">
                  <div class="img-seccar">
                    <img src="images/rate.png" alt="" />
                  </div>
                  <div class="customer-trust">
                    <div class="cHead">
                      We provide the best interest rates from the different
                      banks
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="inner-trust-sec">
                  <div class="img-seccar">
                    <img src="images/far-deal.png" alt="" />
                  </div>
                  <div class="customer-trust">
                    <div class="cHead">Complete Transparency And fair deal</div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="inner-trust-sec">
                  <div class="img-seccar">
                    <img src="images/schedules.png" alt="" />
                  </div>
                  <div class="customer-trust">
                    <div class="cHead">
                      Schedules of the loan and all the papers works
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="apply-text">
              <p>
                <b>R.C. Transfer:</b> We ensure transfer of RC in the buyer name
                and provide copy<br />
                to the seller which provides safety and security to both buyer
                and seller.Complete check of the vehicle
              </p>
              <p>
                <b>On Demand Documents:</b> FOP(Facts On Paper) and NCR (No
                Criminal Record) are also provide on demand of<br />
                buyer. We also provide insurance transfer facility.
              </p>
            </div>
            <!-- <div class="view-all-blog">
                  <a href="javascript:void(0)">Apply Now</a>
               </div> -->
          </div>
        </div>
      </div>
    </section>
    <section class="note-text">
      <div class="container-fluid">
        <div class="row">
          <div class="text-title">
            <marquee
              ><b>Note:</b>Make sure you check exactly what documents the lender
              require from <b>Justacar.in</b>. The documents required can change
              depending on your situation too</marquee
            >
          </div>
        </div>
      </div>
    </section>
    <Inquiryformmodal
      InquiryType="UCP"
      :showTitle="false"
      :showModal="showModal"
      :onClose="onClose"
    />
  </div>
</template>
<script>
import Inquiryformmodal from "./common/Inquiryformmodal";
export default {
  name: "Usedcarloan",
  components: { Inquiryformmodal },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    onClose: function (status) {
      this.showModal = status;
    },
  },
};
</script>