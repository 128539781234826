<template>
  <div>
    <section class="about-us-sec buyacar_bg">
      <div class="container">
        <div class="col-md-12">
          <div class="sell-car-text text-change-usedcar">
            <span>Are You Looking</span>
            <h3>TO BUY A CAR?</h3>
            <div class="sell-car-btn">
              <a href="javascript:void(0)" @click="onClose(true)"
                >search your car</a
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <Homesearch :brands="brands" />

    <section class="shop-by-brand">
      <div class="container">
        <div class="row">
          <h3>Shop by brand</h3>
        </div>
        <div class="row">
          <div class="col-md-9 mx-auto">
            <div class="brand-list">
              <ul>
                <li>
                  <a href="javascript:void(0)" @click="searchQuerym('maruti-suzuki')">
                    <img src="images/Maruti.png" class="default-img" atl="" />

                    <p>Maruti</p>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="searchQuerym('hyundai')">
                    <img src="images/hyndai.png" class="default-img" atl="" />

                    <p>Hyndai</p>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="searchQuerym('mahindra')">
                    <img
                      src="images/mahindra-car.png"
                      class="default-img"
                      atl=""
                    />

                    <p>Mahindra</p>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="searchQuerym('toyota')">
                    <img src="images/toyota.png" class="default-img" atl="" />

                    <p>Toyota</p>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="searchQuerym('honda')">
                    <img
                      src="images/honda-car.png"
                      class="default-img"
                      atl=""
                    />

                    <p>Honda</p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="schedule-your-free" id="get-in-touch">
      <div class="container">
        <div class="col-md-12">
          <div class="col-md-7 maine-scde-sec">
            <div class="schedule-inner-sec">
              <p>Get in Touch</p>

              <h3>Send Us Message</h3>

              <div class="form-start-help">
                <div class="col-md-6">
                  <div class="form-group">
                    <select class="form-control" id="sel1">
                      <option>New car:</option>
                      <option>Insurance /Claim</option>
                      <option>Home Loan</option>
                      <option>Loan Against property</option>
                      <option>Used car Sale</option>
                      <option>Used car Purcase</option>
                      <option>Feed back and suggestion</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Name"
                      id="name"
                    />
                    <span class="user_icons"
                      ><i class="fa fa-user" aria-hidden="true"></i
                    ></span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Enter Email"
                      id="name"
                    />
                    <span class="user_icons"
                      ><i class="fa fa-envelope" aria-hidden="true"></i
                    ></span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      placeholder="Enter Mobile Number"
                    />
                    <span class="user_icons"
                      ><i class="fa fa-mobile" aria-hidden="true"></i
                    ></span>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <textarea
                      class="form-control"
                      rows="5"
                      id="comment"
                      placeholder="Massage:"
                    ></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="btn-section">
                    <button type="button" class="btn btn-primary sumbit-btn">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Inquiryformmodal
      InquiryType="UCS"
      :showTitle="false"
      :showModal="showModal"
      :onClose="onClose"
    />
  </div>
</template>
<script>
import Inquiryformmodal from "./common/Inquiryformmodal";
import Homesearch from "./sections/Homesearch";
import Axios from "axios";
export default {
  name: "Byusedcar",
  components: { Inquiryformmodal, Homesearch },
  data() {
    return {
      brands: [],
      showModal: false,
    };
  },
  methods: {
    onClose: function (status) {
      this.showModal = status;
    },
    searchQuerym: function (make) {
      var data = {
        MakeId: make,
        FuelType: null,
        TransType: null,
        OrderBy: "1",
        OrderAsc: false,
        PageNo: 1,
        PageSize: 36,
        ValueStart: 0,
        ValueEnd: 20000000,
        model: null,
      };
      sessionStorage.setItem("search_payload", JSON.stringify(data));
      this.$router.push("/search");
    }
  },
  mounted() {
    Axios.get(`https://www.justacar.in/lap/api/Vehicle/PopularBrands`).then(
      (res) => {
        var brands = [];
        res.data.body.items.forEach((br) => {
          var x = {
            name: br,
            logo: `images/${br}.png`,
            logobig: `images/brand-big/${br}.png`,
          };
          brands.push(x);
        });
        this.brands = brands;
      }
    );
  },
};
</script>
