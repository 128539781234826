<template>
  <section class="selected-form">
    <div class="container">
      <div class="form">
        <ul>
          <li>
            <div class="form-group">
              <select
                class="form-control"
                id="search_make"
                v-model="search_make"
                v-on:change="getModels($event)"
              >
                <option value="">Make</option>
                <option
                  v-for="(brand, key) in brands"
                  :key="key"
                  :value="brand"
                >
                  {{ brand.toUpperCase() }}
                </option>
              </select>
            </div>
          </li>
          <li>
            <div class="form-group">
              <select
                class="form-control"
                id="search_model"
                v-model="search_model"
              >
                <option value="">Model</option>
                <option
                  v-for="(model, key) in models"
                  :key="key"
                  :value="model.modelvalue"
                >
                  {{ model.modelname }}
                </option>
              </select>
            </div>
          </li>
          <li>
            <div class="form-group">
              <select
                class="form-control"
                id="body_type"
                v-model="search_body_type"
              >
                <option value="">Body Type</option>
                <option value="HB">Hatchback</option>
                <option value="SD">Sedan</option>
                <option value="SU">SUV</option>
                <option value="MU">MUV</option>
                <option value="CP">Coupe</option>
                <option value="SALOON">SALOON</option>
                <option value="TRACTOR">TRACTOR</option>
                <option value="PICKUP">PICKUP</option>
              </select>
            </div>
          </li>
          <li>
            <div class="form-group">
              <select
                class="form-control"
                id="search_fuel_type"
                v-model="search_fuel_type"
              >
                <option value="">Fuel Type</option>
                <option value="DL">Diesel</option>
                <option value="HY">Hybrid</option>
                <option value="EL">Electric</option>
                <option value="LP">LPG</option>
                <option value="CN">CNG</option>
                <option value="PT">Petrol</option>
              </select>
            </div>
          </li>
          <li>
            <div class="form-group">
              <select
                class="form-control"
                id="search_transmission"
                v-model="search_transmission"
              >
                <option value="">Transmission</option>
                <option value="M">Manual</option>
                <option value="AT">Automatic</option>
              </select>
            </div>
          </li>
        </ul>
        <ul class="sec-form-section">
          <li>
            <div class="form-group">
              <!-- <select class="form-control" id="color" v-model="search_color">
                <option value="">Color</option>
                <option v-for="(color, index) in colors" :key="index">
                  {{ color }}
                </option>
              </select> -->
              <input
                type="text"
                class="form-control"
                id="search_color"
                placeholder="Color"
                v-model="search_color"
              />
            </div>
          </li>
          <li class="range-sec">
            <h6>&#8377;{{ search_price }}</h6>
            <div class="form-group increase-range">
              <input
                type="range"
                min="1"
                max="2000000"
                class="form-control-range"
                id="myRange"
                v-model="search_price"
              />
            </div>
          </li>
          <li>
            <div class="form-group">
              <select
                class="form-control"
                id="search_mfg_start"
                v-model="search_mfg_start"
              >
                <option value="">Manufactured Start</option>
                <option v-for="year in fromyears" :value="year" :key="year">
                  {{ year }}
                </option>
              </select>
            </div>
          </li>
          <li>
            <div class="form-group">
              <select
                class="form-control"
                id="search_mfg_end"
                v-model="search_mfg_end"
              >
                <option value="">Manufactured End</option>
                <option v-for="year in toyears" :value="year" :key="year">
                  {{ year }}
                </option>
              </select>
            </div>
          </li>

          <li v-if="more_filter">
            <div class="form-group">
              <input
                type="Number"
                min="0"
                class="form-control"
                id="search_owner_number"
                placeholder="Owner No."
                v-model="search_owner_number"
              />
            </div>
          </li>
          <li v-if="more_filter">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                id="search_odo_start"
                placeholder="Odometer Start"
                v-model="search_odo_start"
              />
            </div>
          </li>
          <li v-if="more_filter">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                id="search_odo_end"
                placeholder="Odometer End"
                v-model="search_odo_end"
              />
            </div>
          </li>
          <li v-if="more_filter">
            <h6>Insurance Expiry Start</h6>
            <div class="form-group">
              <input
                type="date"
                class="form-control"
                placeholder="Manufactured Start"
                id="ins_expire_start"
                v-model="ins_expire_start"
                :max="new Date().toISOString().split('T')[0]"
              />
              <!-- <span class="calendar"
                  ><img src="images/calendar.png" alt=""
                /></span> -->
            </div>
          </li>
          <li v-if="more_filter">
            <h6>Insurance Expiry End</h6>
            <div class="form-group">
              <input
                type="date"
                class="form-control"
                placeholder="Manufactured
              End"
                id="ins_expire_end"
                v-model="ins_expire_end"
                :min="ins_expire_start"
                :max="new Date().toISOString().split('T')[0]"
              />
              <!-- <span class="calendar"
                ><img src="images/calendar.png" alt=""
              /></span> -->
            </div>
          </li>
          <li>
            <div class="form-group">
              <button type="button" @click="searchQuery">
                <i class="fa fa-search" aria-hidden="true"></i> Search
              </button>
            </div>
          </li>
        </ul>
        <div class="col-md-12">
          <div class="cler-all-sec">
            <a href="javascript:void(0)" v-on:click="clearFilter">Clear All</a>
            <a
              href="javascript:void(0)"
              class="filter"
              v-if="!more_filter"
              @click="more_filter = true"
            >
              More Filters
              <i class="fa fa-angle-double-down" aria-hidden="true"></i>
            </a>
            <a
              href="javascript:void(0)"
              class="filter"
              v-if="more_filter"
              @click="lessFilter"
            >
              Less Filters
              <i class="fa fa-angle-double-up" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
const modeldata = require("../../makemodels.json");
export default {
  name: "Filters",
  props: {
    searchItems: Function,
    payload: Object,
    page: Number,
    colors: Array,
  },
  data() {
    return {
      more_filter: false,
      search_make: this.payload.MakeId ? this.payload.MakeId : "",
      search_model: "",
      search_fuel_type: this.payload.FuelType ? this.payload.FuelType : "",
      search_transmission: this.payload.TransType ? this.payload.TransType : "",
      search_color: "",
      search_body_type: "",
      search_owner_number: "",
      search_mfg_start: "",
      search_mfg_end: "",
      search_odo_start: "",
      search_odo_end: "",
      search_price:
        this.payload.ValueEnd !== "" ? this.payload.ValueEnd : 2000000,
      ins_expire_start: "",
      ins_expire_end: "",
      brands: [],
      models: [],
    };
  },
  computed: {
    fromyears() {
      console.log(this.colors);
      const year =
        this.search_mfg_end == ""
          ? new Date().getFullYear()
          : this.search_mfg_end;

      return Array.from(
        { length: +year - 1994 },
        (value, index) => year - index
      );
    },
    toyears() {
      const year = new Date().getFullYear(),
        tow = this.search_mfg_start === "" ? 1994 : this.search_mfg_start - 1;
      return Array.from(
        { length: year - +tow },
        (value, index) => year - index
      );
    },
  },
  methods: {
    lessFilter: function () {
      this.search_odo_start = "";
      this.search_odo_end = "";
      this.ins_expire_start = "";
      this.ins_expire_end = "";
      this.search_owner_number = "";
      this.more_filter = false;
    },
    searchQuery: function () {
      var data = {
        MakeId: this.search_make !== "" ? this.search_make : null,
        FuelType: this.search_fuel_type !== "" ? this.search_fuel_type : null,
        TransType:
          this.search_transmission !== "" ? this.search_transmission : null,
        OrderBy: "1",
        OrderAsc: false,
        PageNo: this.page,
        PageSize: 36,
        Color: this.search_color !== "" ? this.search_color : null,
        MfgDateStart:
          this.search_mfg_start !== ""
            ? this.search_mfg_start + "-01-01"
            : null,
        MfgDateEnd:
          this.search_mfg_end !== "" ? this.search_mfg_end + "-01-01" : null,
        OdoStart: this.search_odo_start !== "" ? this.search_odo_start : null,
        OdoEnd: this.search_odo_end !== "" ? this.search_odo_end : null,
        ValueStart: 0,
        ValueEnd: this.search_price,
        BodyType: this.search_body_type !== "" ? this.search_body_type : null,
        InsExpStart:
          this.ins_expire_start !== "" ? this.ins_expire_start : null,
        InsExpEnd: this.ins_expire_end !== "" ? this.ins_expire_end : null,
      };
      sessionStorage.setItem("search_payload", JSON.stringify(data));
      this.searchItems();
    },
    getModels: function (e) {
      var brand = e.target.value;
      this.models = [];
      modeldata.forEach((make) => {
        if (brand === make.brand) {
          this.models.push(make);
        }
      });
    },
    clearFilter: function () {
      this.search_make = "";
      this.search_model = "";
      this.search_fuel_type = "";
      this.search_transmission = "";
      this.search_color = "";
      this.search_body_type = "";
      this.search_owner_number = "";
      this.search_mfg_start = "";
      this.search_mfg_end = "";
      this.search_odo_start = "";
      this.search_odo_end = "";
      this.search_price = 10000000;
      this.searchQuery();
    },
  },
  mounted() {
    console.log("payload", this.payload);
    if (this.payload.MakeId) {
      this.models = [];
      modeldata.forEach((make) => {
        if (this.payload.MakeId === make.brand) {
          this.models.push(make);
        }
      });
      this.search_model = this.payload.model;
    }
    modeldata.forEach((make) => {
      if (!this.brands.includes(make.brand)) {
        this.brands.push(make.brand);
      }
    });
  },
};
</script>